import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DividendsReport } from '../types/dividendsReport';
import { EarningsReport } from '../types/earningsReport';
import { IPO } from '../types/ipos';
import { MacroEvent } from '../types/macroEvent';
import { StockSplit } from '../types/stock-splits';
import { useEconCalendarParams } from './use-econ-calendar-params';
import { HistoricalData } from '../types/historicalData';
import { useTranslation } from 'react-i18next';

import {
  handleEconProcessing,
  filterDataOnDateFilters,
  filterDatesOnDateFilters,
} from '../utils/econ-utils';
import {
  continents,
  getDateRangeAccountTime,
  getDateRangeLocal,
  importanceMapping,
  getCountryCode,
} from '../utils/util';
import { getEconData, getHistoricalData, getLatestUpdates } from '../api/EconCalendarApi';
import { useSessionData } from './use-session-data';

type EconCalContextType = {
  authString: string;
  econData: {};
  data: Record<string, DividendsReport[] | EarningsReport[] | IPO[] | MacroEvent[] | StockSplit[]>;
  activeTab: string;
  changeActiveTab: (id: string) => void;
  changeDateFilter: (dateFilter: string, value: string) => void;
  fetchHistoricalData: (id: string) => void;
  dates: string[];
  chartData: HistoricalData[];
  allCountry: string[];
  selectedContinents: string[];
  selectCountry: (id: string) => void;
  selectedImportance: string[];
  handleImportance: (id: string) => void;
  selectedChartSection: MacroEvent;
  isDataLoading: boolean;
  isChartLoading: boolean;
  isExpanded: boolean;
  handleSelectDeselect: (name: string, type: string) => void;
  updateResultData: () => void;
  activeDateFilter: string[];
  updateExtendedImportanceFilter: () => void;
  updateExtendedCountryFilter: () => void;
  extendedImportanceFilter: boolean;
  extendedCountryFilter: boolean;
  updateContinentSelection: (name: string, type: string) => void;
  filteredDates: {};
  updateSearchText: (text: string) => void;
  isTabChanged: boolean;
  showDetail: boolean;
  toggleDetail: (data: {} | string) => void;
  eventDetailValue: {} | string;
  expandTabs: boolean;
  updateExpandTabs: (value: boolean) => void;
  showSearch: boolean;
  updateShowSearch: () => void;
  showFilters: boolean;
  updateShowFilters: () => void;
  errorMessage: null | string;
  updatedData: object;
  showBanner: boolean;
  updateShowBanner: (value: boolean) => void;
  searchText: string;
};

export const EconCalendarContext = createContext<EconCalContextType | null>(null);

export function useEconCalendar() {
  return useContext(EconCalendarContext);
}

export const tabsTitles = {
  events: 'Macroeconomic Events',
  'earnings-reports': 'Earnings',
  'dividends-reports': 'Dividends',
  ipos: 'IPOs',
  'stock-splits': 'Stock Splits',
};

export const EconCalendarProvider = ({
  authString,
  handleFilter,
  appliedFilters,
  selectedTabs,
  isAddToWorkspaceClicked,
  children,
}) => {
  const { t } = useTranslation();
  const [econData, setEconData] = useState({});
  const [data, setData] = useState({});
  const [activeTab, setActiveTab] = useState('events');
  const [activeDateFilter, setActiveDateFilter] = useState(['today', '']);
  const [dates, setDates] = useState([]);
  const [filteredDates, setFilteredDates] = useState({});
  const { locale } = useEconCalendarParams();
  const [chartData, setChartData] = useState([]);
  const [allCountry, setAllCountry] = useState(
    Object.values(continents).flatMap((countryList) => countryList)
  );

  const [selectedChartSection, setSelectedChartSection] = useState();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isChartLoading, setIsChartLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedImportance, setSelectedImportance] = useState(['high', 'medium', 'low']);
  const [extendedImportanceFilter, setExtendedImportanceFilter] = useState(false);
  const [extendedCountryFilter, setExtendedCountryFilter] = useState(false);
  const [selectedContinents, setSelectedContinents] = useState(Object.keys(continents));
  const [searchText, setSearchText] = useState('');
  const [isTabChanged, setIsTabChanged] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [eventDetailValue, setEventDetailValue] = useState('');
  const [expandTabs, setExpandTabs] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [updatedData, setUpdatedData] = useState({});
  const [showBanner, setShowBanner] = useState(false);
  // const [retainFilterFlag, setRetainFilterFlag] = useState(false);

  const allFilters = {
    selectedImportance: selectedImportance,
    allCountry: allCountry,
    selectedContinent: selectedContinents,
  };

  const { cst, tastyApiUrl, timezoneOffset } = useSessionData();

  const ref = useRef(true);

  const updateExpandTabs = (value = null) => {
    if (value !== null) setExpandTabs(value);
    else setExpandTabs((prev) => !prev);
  };

  const updateShowSearch = () => {
    setShowSearch((prev) => !prev);
  };

  const updateShowFilters = () => {
    setShowFilters((prev) => !prev);
  };

  const updateShowBanner = (value: boolean) => {
    setShowBanner(value);
  };

  const changeActiveTab = (id) => {
    setIsDataLoading(true);
    setData({});
    setEconData({});
    setDates([]);
    setChartData([]);
    setActiveTab(id);
    setIsExpanded(false);
    handleFilter('selectedTabs', id);
  };

  const changeDateFilter = (dateFilter: string, value: '') => {
    if (dateFilter !== 'custom date') {
      setIsDataLoading(true);
      setIsTabChanged(true);
      setData(econData[dateFilter]);
      setDates(filteredDates[dateFilter]);
      setChartData([]);
      setActiveDateFilter([dateFilter, '']);
      setIsExpanded(false);
    } else if (value === '') {
      setActiveDateFilter(['today', value]);
      fetchData(value, 'today');
      setChartData([]);
    } else {
      fetchData(value, dateFilter);
      setActiveDateFilter([dateFilter, value]);
      setChartData([]);
      setIsExpanded(false);
    }
  };

  const toggleDetail = (data = '') => {
    if (data !== '') {
      setEventDetailValue(data);
      setShowDetail(true);
    } else if (data == '') {
      if (eventDetailValue !== '') setEventDetailValue('');
      if (showDetail !== false) setShowDetail(false);
    }
  };

  const updateExtendedImportanceFilter = () => {
    if (!extendedImportanceFilter && extendedCountryFilter) setExtendedCountryFilter(false);
    setExtendedImportanceFilter((prev) => !prev);
  };

  const updateExtendedCountryFilter = () => {
    if (!extendedCountryFilter && extendedImportanceFilter) setExtendedImportanceFilter(false);
    setExtendedCountryFilter((prev) => !prev);
  };

  const updateSearchText = (text: string) => {
    setSearchText(text);
  };

  const updateData = async (updatedValues) => {
    // Create a copy of the data to avoid mutating state directly
    const newData = { ...data };

    // Loop over the updatedData object
    for (const key in updatedValues) {
      const updatedEntry = updatedValues[key];

      // Loop over the dates in the data object
      for (const date in newData) {
        const entries = newData[date];

        // Find the entry with the same id
        const entryIndex = entries.findIndex((entry) => entry.id === updatedEntry.id);

        if (entryIndex !== -1) {
          // Compare lastUpdate values
          if (new Date(updatedEntry.lastUpdate) > new Date(entries[entryIndex].lastUpdate)) {
            if (updatedEntry.event === '') updatedEntry.event = entries[entryIndex].event;
            if (!updatedEntry.description)
              updatedEntry.description = entries[entryIndex].description;
            if (!updatedEntry.category) {
              updatedEntry.category = entries[entryIndex].category;
            }
            // Update the entry if the updatedData entry has a later lastUpdate
            newData[date][entryIndex] = updatedEntry;
          }
        }
      }
    }

    // Update the state with the new data
    if (newData && Object.values(newData).length) setData(newData);
  };

  // checks whether the last polled data is there, if it's there it compares the lastUpdate and updates the data in the local variable
  // the local variable is finally set as the new updated data
  const handlePolling = () => {
    if (data && Object.values(data).length === 0) return null;
    const timerId = setInterval(async () => {
      try {
        const resp = await getLatestUpdates(cst, authString, tastyApiUrl);

        const respData = await resp.json();
        if (respData.length > 0) {
          const updatedValues = {};
          respData.forEach((value, index) => {
            if (value.id in updatedData) {
              if (new Date(value.lastUpdate) > new Date(updatedData[value.id].lastUpdate)) {
                if (value.event === '') value.event = updatedData[value.id].event;
                if (!value.description) value.description = updatedData[value.id].description;
                updatedValues[value.id] = value;
              }
            } else {
              updatedValues[value.id] = value;
            }
          });
          setUpdatedData(updatedValues);
          setTimeout(async () => await updateData(updatedValues), 1000);
          setTimeout(() => setUpdatedData({}), 1000);
        } else {
          setUpdatedData({});
        }
      } catch (error) {
        console.error(error, 'Err');
      }
    }, 5000);
    return timerId;
  };

  // value will be either '' or date and filter will be today/tomorrow etc
  const fetchData = async (value, filter: string) => {
    try {
      setIsDataLoading(true);
      const [fromDate, toDate] =
        timezoneOffset !== null
          ? getDateRangeAccountTime(activeTab, timezoneOffset, value)
          : getDateRangeLocal(activeTab, value);
      const resp = await getEconData(
        { activeTab, fromDate, toDate, locale },
        cst,
        authString,
        tastyApiUrl
      );
      if (resp.status === 200) {
        const respData = await resp.json();
        const { datesData, processedData } = handleEconProcessing(respData);
        const filteredDates = filterDatesOnDateFilters(datesData, value === '', timezoneOffset);
        const dateFilteredData = filterDataOnDateFilters(
          processedData,
          value === '',
          timezoneOffset
        );
        if (value === '') {
          setEconData(() => ({ ...dateFilteredData, 'custom date': '' }));
          setFilteredDates(() => ({ ...filteredDates, 'custom date': '' }));
        } else {
          setEconData((prev) => ({ ...prev, 'custom date': dateFilteredData['custom date'] }));
          setFilteredDates((prev) => ({ ...prev, 'custom date': filteredDates['custom date'] }));
        }

        if (dateFilteredData[filter] && Object.keys(dateFilteredData[filter]).length)
          updateResultData(dateFilteredData[filter], filteredDates[filter]);
        else {
          setData({});
          setDates([]);
        }
        setIsDataLoading(false);
      } else {
        throw new Error();
      }
    } catch (error) {
      setErrorMessage(t('Problem with data fetching. Please try again in some time'));
      setIsDataLoading(false);
    }
  };

  const updateResultData = (data = {}, dates = []) => {
    const { hasFilters, countryFilterCheck, importanceFilterCheck } = checkFilters();
    if (Object.keys(data).length === 0) {
      data = econData[activeDateFilter[0]] || {};
      dates = filteredDates[activeDateFilter[0]];
    }

    if (!countryFilterCheck) {
      setErrorMessage(t('No events to display. Change the date range or broaden your filters.'));
      setData({});
      setDates([]);
    } else if (searchText) {
      let newData = {};

      const searchedData = getSearchFilteredData(data);
      if (Object.keys(searchedData).length === 0) {
        setErrorMessage(t('Sorry, no results match your search. Please try again.'));
      } else {
        newData = hasFilters
          ? filterData(searchedData, countryFilterCheck, importanceFilterCheck)
          : searchedData;

        if (Object.keys(newData).length === 0) {
          setErrorMessage(
            t('No events to display. Change the date range or broaden your filters.')
          );
        }
      }
      setData(newData);
      setDates(dates);
    } else if (hasFilters) {
      const newData = filterData(data, countryFilterCheck, importanceFilterCheck);
      if (!newData && Object.keys(newData).length === 0)
        setErrorMessage(t('No events to display. Change the date range or broaden your filters.'));
      setData(newData);
      setDates(dates);
    } else {
      if (!data && Object.keys(data).length === 0)
        setErrorMessage(t('No events to display. Change the date range or broaden your filters.'));
      setData(data);
      setDates(dates);
    }
    handleFilter('econFilters', allFilters);
    setIsDataLoading(false);
  };

  const getSearchFilteredData = (data) => {
    const newData = {};
    data &&
      Object.entries(data).forEach(([entryDate, entryDateEvents]) => {
        //@ts-ignore
        entryDateEvents.map((item) => {
          if (
            item.event?.toLowerCase().includes(searchText.toLowerCase()) ||
            item.name?.toLowerCase().includes(searchText.toLowerCase())
          ) {
            if (!newData[entryDate]) {
              newData[entryDate] = [item];
            } else newData[entryDate] = [...newData[entryDate], item];
          }
        });
      });
    return newData;
  };

  const retainFilterFlag = useMemo(() => {
    if (
      isAddToWorkspaceClicked &&
      !retainFilterFlag &&
      (Object.values(appliedFilters).length > 0 || (selectedTabs && selectedTabs !== activeTab))
    ) {
      return true;
    }
    return false;
  }, [isAddToWorkspaceClicked, appliedFilters, selectedTabs, activeTab]);

  const checkFilters = () => {
    const completeCountryLength = Object.values(continents).flatMap(
      (countryList) => countryList
    ).length;

    if (
      isAddToWorkspaceClicked &&
      retainFilterFlag &&
      (Object.values(appliedFilters).length > 0 || (selectedTabs && selectedTabs !== activeTab))
    ) {
      // change the code to retain the tabs as well here
      // setRetainFilterFlag(true);
      setActiveTab(selectedTabs);
      const { allCountry, selectedImportance, selectedContinent } = appliedFilters;
      setAllCountry(allCountry);
      setSelectedImportance(selectedImportance);
      setSelectedContinents(selectedContinent);
      handleFilter('isAddToWorkspaceClicked', false);
    }
    const countryFilterCheck =
      allCountry && allCountry.length > 0 && allCountry.length <= completeCountryLength;
    const importanceFilterCheck =
      ['events', 'earnings-reports'].includes(activeTab) &&
      selectedImportance &&
      selectedImportance.length > 0 &&
      selectedImportance.length < 3;

    const hasFilters = countryFilterCheck || importanceFilterCheck;
    return { hasFilters, countryFilterCheck, importanceFilterCheck };
  };

  const checkCountryFilter = (eventData, importanceFilter) => {
    let countryCode;
    if (activeTab === 'dividends-reports') {
      countryCode = eventData?.symbol?.split(':')[1];
    } else {
      let countryName = eventData.country;
      if (eventData.country === 'European Union') countryName = 'Euro Area';
      countryCode = getCountryCode(countryName);
      countryCode = countryCode && countryCode.length > 0 && countryCode[0][0];
    }
    if (countryCode && countryCode.length > 0 && allCountry.includes(countryCode)) {
      if (importanceFilter) {
        if (activeTab === 'events') {
          return checkImportanceFilter(eventData);
        } else {
          return checkImportanceFilter(eventData);
        }
      } else return true;
    }
    return false;
  };

  const checkImportanceFilter = (eventData) => {
    return selectedImportance.includes(importanceMapping[eventData.importance]);
  };

  const filterData = (processedData, countryFilter: boolean, importanceFilter: boolean) => {
    const filtered = {};

    processedData &&
      Object.entries(processedData).forEach(([keyDate, eventDatas]) => {
        // @ts-ignore
        eventDatas.map((eventData) => {
          const filterFlag = checkCountryFilter(eventData, importanceFilter);
          if (filterFlag) {
            if (!filtered[keyDate]) {
              filtered[keyDate] = [eventData];
            } else {
              filtered[keyDate] = [...filtered[keyDate], eventData];
            }
          }
        });
      });
    return filtered;
  };

  const fetchHistoricalData = async (selectedData) => {
    try {
      //@ts-ignore
      if (selectedData.id === selectedChartSection?.id && isExpanded) {
        setIsExpanded(false);
        return;
      }

      setIsExpanded(true);
      setIsChartLoading(true);
      setSelectedChartSection(selectedData);

      const resp = await getHistoricalData(selectedData.ticker, cst, authString, tastyApiUrl);
      if (resp.status === 200) {
        const data = await resp.json();
        setChartData(data);
      } else {
        setChartData([]); // Ensure chartData is cleared if response is not successful
      }
    } catch (error) {
      console.error('Error fetching historical data:', error);
      setChartData([]); // Ensure chartData is cleared on error
    } finally {
      setIsChartLoading(false); // Ensure loading state is always reset
    }
  };

  const selectCountry = (code: string) => {
    if (allCountry.includes(code)) {
      const resp = allCountry.filter((item) => item !== code);
      setAllCountry(resp);

      const relevantContinent = selectedContinents.filter((value) => {
        if (continents[value].includes(code)) return value;
      });
      const countries = continents[relevantContinent[0]];

      if (!(countries && countries.some((val) => resp.includes(val)))) {
        updateContinentSelection(relevantContinent[0], 'deselect');
      }
    } else {
      const relevantContinent = Object.keys(continents).filter((value) => {
        if (continents[value].includes(code)) return value;
      });

      const countries = continents[relevantContinent[0]];
      if (!countries.every((country) => allCountry.includes(country)))
        updateContinent(relevantContinent[0]);

      setAllCountry((prev) => [...prev, code]);
    }
  };

  const updateContinent = (name: string) => {
    let resp = [...selectedContinents, name];
    setSelectedContinents(resp);
  };

  const updateContinentSelection = (name: string, type: string) => {
    if (type === 'select') {
      let resp = [...selectedContinents, name];
      setSelectedContinents(resp);
      handleSelectDeselect(name, type);
    } else {
      let resp = selectedContinents;
      let filteredResp = resp.filter((item) => item !== name);
      setSelectedContinents(filteredResp);
      handleSelectDeselect(name, type);
    }
  };

  const handleSelectDeselect = (name: string, type: string) => {
    if (type === 'select') {
      let resp = [];
      for (const countryCode of continents[name]) {
        if (!allCountry.includes(name)) {
          resp = [...resp, countryCode];
        }
      }
      setAllCountry([...allCountry, ...resp]);
    } else {
      let resp = [...allCountry];
      let filteredResp = resp.filter((item) => !continents[name].includes(item));
      setAllCountry(filteredResp);
    }
  };

  const handleImportance = (importance) => {
    if (selectedImportance.includes(importance)) {
      const resp = selectedImportance.filter((item) => item !== importance);
      setSelectedImportance(resp);
    } else {
      setSelectedImportance((prev) => [...prev, importance]);
    }
  };

  useEffect(() => {
    if (!isAddToWorkspaceClicked) {
      const [dateFilter] = activeDateFilter;
      setActiveDateFilter([dateFilter, '']);
      fetchData('', dateFilter);
    } else {
      updateResultData();
    }
  }, [activeTab, isAddToWorkspaceClicked]);

  useEffect(() => {
    const timerId = handlePolling();

    return () => clearInterval(timerId);
  }, [data]);

  useEffect(() => {
    if (isTabChanged) {
      updateResultData();
      setIsTabChanged(false);
    }
  }, [isTabChanged]);

  useEffect(() => {
    if (!ref.current) {
      updateResultData();
    }
  }, [searchText, allCountry, selectedImportance]);

  useEffect(() => {
    ref.current = false;
  }, []);

  return (
    <EconCalendarContext.Provider
      value={{
        updatedData,
        errorMessage,
        econData,
        data,
        activeTab,
        changeActiveTab,
        changeDateFilter,
        dates,
        fetchHistoricalData,
        chartData,
        allCountry,
        selectedContinents,
        selectCountry,
        selectedChartSection,
        isDataLoading,
        isExpanded,
        isChartLoading,
        handleSelectDeselect,
        selectedImportance,
        handleImportance,
        updateResultData,
        activeDateFilter,
        updateExtendedImportanceFilter,
        updateExtendedCountryFilter,
        extendedCountryFilter,
        extendedImportanceFilter,
        updateContinentSelection,
        filteredDates,
        updateSearchText,
        isTabChanged,
        showDetail,
        toggleDetail,
        eventDetailValue,
        expandTabs,
        updateExpandTabs,
        showSearch,
        updateShowSearch,
        showFilters,
        updateShowFilters,
        showBanner,
        updateShowBanner,
        searchText,
        authString,
      }}
    >
      {children}
    </EconCalendarContext.Provider>
  );
};
