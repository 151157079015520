const ENV_URLS = {
  test: {
    apiHost: 'https://net-deal.ig.com',
    tastyApiUrl: 'https://net-api.ig.com/explore',
    tastyUserName: 'tasty',
    tastyUserPassword: 'yummy',
  },
  uat: {
    apiHost: 'https://web-deal.ig.com',
    tastyApiUrl: 'https://web-api.ig.com/explore',
    tastyUserName: 'tasty',
    tastyUserPassword: 'yummy',
  },
  demo: {
    apiHost: 'https://demo-deal.ig.com',
    tastyApiUrl: 'https://api-demo.igexploreprd.ig.com/explore',
    tastyUserName: 'calendar',
    tastyUserPassword: 'SuPeRlOnGpA$$$w00rd5671',
  },
  prod: {
    apiHost: 'https://deal.ig.com',
    tastyApiUrl: 'https://api-prd.igexploreprd.ig.com/explore',
    tastyUserName: 'calendar',
    tastyUserPassword: 'SuPeRlOnGpA$$$w00rd5671',
  },
};

export const getEnvURLs = (env: string) => {
  const dataKey = env?.toLowerCase();

  if (!Object.keys(ENV_URLS).includes(dataKey)) {
    return {
      apiHost: '',
      tastyApiUrl: '',
      tastyUserName: '',
      tastyUserPassword: '',
    };
  }

  return ENV_URLS[dataKey];
};
