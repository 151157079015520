import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const useScreenDimensions = () => {
  const [isMobile, setIsMobile] = useState(getWindowDimensions().width < 768);
  useEffect(() => {
    function handleResize() {
      const { width } = getWindowDimensions();
      setIsMobile(width < 768);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

export default useScreenDimensions;
