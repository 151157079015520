// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XyyGir19UMG9YUyHohCt {
  width: var(--desktop-container-width);
}

.XAvFMoAEnzWVF_3YXwcO {
  width: 100%;
}

.m_a9rD8X4gUjN3u3H_ZM {
  line-height: 16px;
  font-size: 11px;
  color: var(--secondary-text-color);
  font-family: 'Matter IG';
  font-weight: 400;
  height: 16px;
  text-transform: uppercase;
}

.XSYyL5c5CqSoW7rasxYw {
  display: flex;
  flex-direction: column;
  gap: 1px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/EconCalendarTableRows.module.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;AACvC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,kCAAkC;EAClC,wBAAwB;EACxB,gBAAgB;EAChB,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV","sourcesContent":[".dataWrapper {\n  width: var(--desktop-container-width);\n}\n\n.dataWrapperMobile {\n  width: 100%;\n}\n\n.heading {\n  line-height: 16px;\n  font-size: 11px;\n  color: var(--secondary-text-color);\n  font-family: 'Matter IG';\n  font-weight: 400;\n  height: 16px;\n  text-transform: uppercase;\n}\n\n.tableRowData {\n  display: flex;\n  flex-direction: column;\n  gap: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dataWrapper": `XyyGir19UMG9YUyHohCt`,
	"dataWrapperMobile": `XAvFMoAEnzWVF_3YXwcO`,
	"heading": `m_a9rD8X4gUjN3u3H_ZM`,
	"tableRowData": `XSYyL5c5CqSoW7rasxYw`
};
export default ___CSS_LOADER_EXPORT___;
