import upArrow from '../../assets/svgs/earningsUpArrow.svg';
import downArrow from '../../assets/svgs/earningsDownArrow.svg';
import useScreenDimensions from '../../hooks/useScreenDimension';
import { formatToTwoDecimalPlaces } from '../../utils/util';
import { useTranslation } from 'react-i18next';

const Revenue = ({ marketTrend, singleDataEntry }) => {
  const isMobile = useScreenDimensions();
  const { t } = useTranslation();
  return (
    <div className="revenueWrapper">
      {isMobile && <div className="revenueHeading">{t('Revenue')}: </div>}
      <div className="actual">
        {!isMobile && <div className="label">{t('Actual')}</div>}
        <div
          className={
            singleDataEntry.revenue
              ? marketTrend[1] > 0
                ? 'actualPositive'
                : 'actualNegative'
              : 'noMarketTrend'
          }
        >
          <span className="actualValue">
            {singleDataEntry?.revenue ? formatToTwoDecimalPlaces(singleDataEntry?.revenue) : '-'}
          </span>
          {!isMobile &&
            singleDataEntry.revenue &&
            (marketTrend[1] > 0 ? (
              <img src={upArrow} alt="value increased" loading="lazy" />
            ) : (
              <img src={downArrow} alt="value decreased" loading="lazy" />
            ))}
        </div>
      </div>
      <div className="forecast">
        {!isMobile && <span>{t('Forecast')}</span>}
        <span>
          {singleDataEntry?.revenueForecast
            ? formatToTwoDecimalPlaces(singleDataEntry.revenueForecast)
            : '-'}
        </span>
      </div>
      <div className="previous">
        {!isMobile && <span>{t('Previous')}</span>}
        <span>
          {singleDataEntry?.revenuePrevious
            ? formatToTwoDecimalPlaces(singleDataEntry.revenuePrevious)
            : '-'}
        </span>
      </div>
    </div>
  );
};

export default Revenue;
