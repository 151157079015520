import EconCalendarTableData from './EconCalendarTableData';
import EconCalendarTabs from './EconCalendarTabs';
import style from '../styles/index.module.css';
import { useEconCalendarParams } from '../hooks/use-econ-calendar-params';
import { useEconCalendar } from '../hooks/useEconCalendar';
import MobileDetailsPage from './MobileDetailsPage';
import useScreenDimensions from '../hooks/useScreenDimension';
import EconBanner from './Banner/EconBanner';
import { useSessionData } from '../hooks/use-session-data';

export default function EconCalendar() {
  const { currentTheme } = useEconCalendarParams();
  const { showDetail, showFilters, showBanner } = useEconCalendar();
  const isMobile = useScreenDimensions();
  const showFilterClass = showFilters ? style.tabFilterPageMobile : style.tabFilterMobile;
  const styleClass = isMobile ? showFilterClass : style.tabFilterDesktop;
  const { igCompanySiteId } = useSessionData();

  return (
    <div
      className={`${
        isMobile
          ? style.mainBackgroundMobile
          : showBanner
          ? style.mainBackgroundBanner
          : style.mainBackground
      }`}
      data-econ-calendar-theme={currentTheme}
    >
      <div className={style.calendar_container}>
        <div className={styleClass}>
          {igCompanySiteId !== 'igus' && <EconBanner />}
          <EconCalendarTabs />
        </div>
        {/* 
            Show Table Data 
            showDetail is for toggling the Rows detail to show the description and Chart (For Mobile view)
          */}
        {!showFilters && (showDetail ? <MobileDetailsPage /> : <EconCalendarTableData />)}
      </div>
    </div>
  );
}
