import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './src/locales/en.json';
// import translationDE from './src/locales/de.json';
// import translationES from './src/locales/es.json';
// import translationFR from './src/locales/fr.json';
// import translationIT from './src/locales/it.json';
// import translationNL from './src/locales/nl.json';
// import translationNO from './src/locales/no.json';
// import translationSV from './src/locales/sv.json';
import translationJA from './src/locales/ja.json';

function initialiseI18n(lng = 'en') {
  return i18n.use(initReactI18next).init({
    resources: {
      en: { translation: en },
      // de: { translation: translationDE },
      // es: { translation: translationES },
      // fr: { translation: translationFR },
      ja: { translation: translationJA },
      // it: { translation: translationIT },
      // nl: { translation: translationNL },
      // no: { translation: translationNO },
      // sv: { translation: translationSV },
    },
    lng,
    fallbackLng: 'en',
  });
}

export default initialiseI18n;
