import style from '../../styles/filters/CountryList.module.css';
import { FlagIcon } from 'react-flag-kit';
import { countryCodeToNameMap } from '../../utils/countryCodeNameMapping';
import { useEconCalendar } from '../../hooks/useEconCalendar';
import { useEffect, useRef } from 'react';
import useScreenDimensions from '../../hooks/useScreenDimension';
import { useTranslation } from 'react-i18next';
import CustomCheckbox from './CustomCheckbox';

const CountryList = ({
  continent,
  continents,
  importanceFilterPresent,
  selectedCountryIndex = null,
}) => {
  const { allCountry, selectCountry, updateResultData } = useEconCalendar();
  const firstRender = useRef(true);
  const isMobile = useScreenDimensions();
  const { t } = useTranslation();

  useEffect(() => {
    if (!firstRender.current) {
      updateResultData();
    }
  }, [allCountry]);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return (
    <div
      className={
        isMobile
          ? style.countryListWrapperMobile
          : importanceFilterPresent
          ? style.countryListWrapperLeftMargin
          : style.countryListWrapper
      }
    >
      <div
        className={`${isMobile ? style.countryListMobile : style.countryList} ${
          importanceFilterPresent ? style.countryListLeftMargin : style.countryListNoMargin
        }`}
      >
        {continents[continent].map((country, index) => {
          return (
            <div
              className={`${isMobile ? style.countryMobile : style.country} ${
                selectedCountryIndex === index ? style.applyBorder : ''
              }`}
              key={country}
              data-testid={`country_div_${country}`}
            >
              <input
                type="checkbox"
                id={country}
                checked={allCountry.includes(country)}
                onChange={() => selectCountry(country)}
                className={style.checkbox_input}
              />
              <CustomCheckbox
                onClickHandler={selectCountry}
                value={country}
                checked={allCountry.includes(country)}
              />
              <label
                className={isMobile ? style.countryFlagMobile : style.countryFlag}
                htmlFor={country}
                data-testid={country}
              >
                <span>
                  <FlagIcon code={country} loading="lazy" />
                </span>
                <span> {t(countryCodeToNameMap[country] || country)} </span>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CountryList;
