import { useEffect, useState } from 'react';
import style from './EconBanner.module.css';
import SuccessIcon from '../../assets/SuccessIcon';
import CrossIcon from '../../assets/CrossIcon';
import { useEconCalendar } from '../../hooks/useEconCalendar';
import useScreenDimensions from '../../hooks/useScreenDimension';

export default function EconBanner() {
  const { showBanner, updateShowBanner } = useEconCalendar();
  const isMobile = useScreenDimensions();

  useEffect(() => {
    if (localStorage?.getItem('showEconBanner')) {
      updateShowBanner(false);
    } else {
      updateShowBanner(true);
    }
  }, []);

  const handleBanner = () => {
    updateShowBanner(false);
    localStorage.setItem('showEconBanner', 'false');
  };
  return (
    showBanner && (
      <div
        className={isMobile ? style.econMobileBanner : style.econBanner}
        data-testid="econ-banner"
      >
        <div className={style.econMainWrapper}>
          <SuccessIcon classes={style.overflowVisible} />
          <p className={style.bannerText}>
            You can still access our old Economic Calendar here:{' '}
            <a
              href="https://www.ig.com/uk/ig-economic-calendar"
              target="_blank"
              className={style.highlightLink}
            >
              https://www.ig.com/uk/ig-economic-calendar
            </a>
            . You’ll need to use our old calendar if you want to access alerts functionality or to
            edit or delete alerts you’ve already set. Our new calendar is still in development –
            we’re looking forward to bringing you alerts soon.
            <br />
            <span className={style.highlightText}>PLEASE NOTE:</span> The old calendar will be
            decommissioned on 24th October 2024. Any alerts set to on or after this date will not be
            triggered
          </p>
          <CrossIcon
            classes={`${style.overflowVisible} ${style.cursorPointer}`}
            onClick={handleBanner}
          />
        </div>
      </div>
    )
  );
}
