// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lZ46RXiZGptzmnYOSdqX {
  background-color: var(--banner-background-color);
  padding: 16px;
  border-radius: 2px;
  border: 1px solid var(--banner-border-color);
  margin: 20px;
  box-shadow: 0px 0.5px 0px 0px var(--banner-shadow-color);
}

.kd2yC7jNhJQlWRHhA165 {
  background-color: #e8edf1;
  padding: 8px;
  border-radius: 2px;
  border: 1px solid #619fd6;
  box-shadow: 0px 0.5px 0px 0px #e2eced;
}

.RKm5Zawo4_VEhezI0p8G {
  display: flex;
  gap: 5px;
  flex-wrap: nowrap;
}

.cNgDU6ky6VsNTSo6FZ3U {
  overflow: visible !important;
}

.qaIuu8Lh8TEMw6vhBV_s {
  cursor: pointer;
}

.cwEUthK3f5lFUe3aqk9D {
  margin: 0;
  font-size: 13px;
  font-family: 'MatterIGTF-Regular';
  line-height: 20px;
  color: var(--primary-text-color);
}

.SpJx_zhJ5pjj6mg3Ve26 {
  font-weight: 500;
  line-height: 13px;
  font-family: 'MatterIGTF-Medium';
}

.lTAYH1BSAnJzLq6xOPkb {
  color: var(--banner-text-highlight-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/Banner/EconBanner.module.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;EAChD,aAAa;EACb,kBAAkB;EAClB,4CAA4C;EAC5C,YAAY;EACZ,wDAAwD;AAC1D;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,qCAAqC;AACvC;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,iBAAiB;AACnB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,SAAS;EACT,eAAe;EACf,iCAAiC;EACjC,iBAAiB;EACjB,gCAAgC;AAClC;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,gCAAgC;AAClC;;AAEA;EACE,yCAAyC;AAC3C","sourcesContent":[".econBanner {\n  background-color: var(--banner-background-color);\n  padding: 16px;\n  border-radius: 2px;\n  border: 1px solid var(--banner-border-color);\n  margin: 20px;\n  box-shadow: 0px 0.5px 0px 0px var(--banner-shadow-color);\n}\n\n.econMobileBanner {\n  background-color: #e8edf1;\n  padding: 8px;\n  border-radius: 2px;\n  border: 1px solid #619fd6;\n  box-shadow: 0px 0.5px 0px 0px #e2eced;\n}\n\n.econMainWrapper {\n  display: flex;\n  gap: 5px;\n  flex-wrap: nowrap;\n}\n\n.overflowVisible {\n  overflow: visible !important;\n}\n\n.cursorPointer {\n  cursor: pointer;\n}\n\n.bannerText {\n  margin: 0;\n  font-size: 13px;\n  font-family: 'MatterIGTF-Regular';\n  line-height: 20px;\n  color: var(--primary-text-color);\n}\n\n.highlightText {\n  font-weight: 500;\n  line-height: 13px;\n  font-family: 'MatterIGTF-Medium';\n}\n\n.highlightLink {\n  color: var(--banner-text-highlight-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"econBanner": `lZ46RXiZGptzmnYOSdqX`,
	"econMobileBanner": `kd2yC7jNhJQlWRHhA165`,
	"econMainWrapper": `RKm5Zawo4_VEhezI0p8G`,
	"overflowVisible": `cNgDU6ky6VsNTSo6FZ3U`,
	"cursorPointer": `qaIuu8Lh8TEMw6vhBV_s`,
	"bannerText": `cwEUthK3f5lFUe3aqk9D`,
	"highlightText": `SpJx_zhJ5pjj6mg3Ve26`,
	"highlightLink": `lTAYH1BSAnJzLq6xOPkb`
};
export default ___CSS_LOADER_EXPORT___;
