import React from 'react';

export default function CrossIcon({ classes, onClick }) {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
      onClick={onClick}
    >
      <path
        d="M9.54225 1.1726C9.73951 0.975353 9.73951 0.655542 9.54225 0.458295C9.34502 0.261066 9.02524 0.261061 8.828 0.458283L4.99997 4.28588L1.07121 0.357239C0.87393 0.159963 0.554058 0.160026 0.356853 0.35738C0.159748 0.554635 0.159813 0.874309 0.356997 1.07148L4.28597 5.00026L0.458082 8.82767C0.260783 9.02495 0.260777 9.34482 0.458069 9.5421C0.655342 9.73936 0.975173 9.73936 1.17244 9.5421L5.00024 5.71449L8.92858 9.64287C9.12582 9.84011 9.4456 9.84011 9.64284 9.64288C9.84009 9.44564 9.8401 9.12584 9.64285 8.9286L5.71444 5.00022L9.54225 1.1726Z"
        fill="#5A5E5F"
      />
    </svg>
  );
}
