export const getEconData = async (
  { activeTab, fromDate, toDate, locale = 'en' },
  cst,
  authString,
  apiHost
) => {
  return await fetch(`${apiHost}/${activeTab}?from=${fromDate}&to=${toDate}&lang=${locale}`, {
    headers: new Headers({
      Authorization: `Basic ${authString}`,
      cst,
    }),
  });
};

export const getHistoricalData = async (ticker, cst, authString, apiHost) => {
  return await fetch(`${apiHost}/historical-data/${ticker}`, {
    headers: new Headers({
      Authorization: `Basic ${authString}`,
      cst,
    }),
    method: 'GET',
  });
};

export const getLatestUpdates = async (cst, authString, apiHost) => {
  return await fetch(`${apiHost}/latest-updates?secondsAgo=5&roundSeconds=10`, {
    headers: new Headers({
      Authorization: `Basic ${authString}`,
      cst,
    }),
  });
};

export const downloadIcsFile = async (selectedChartSection: {}, cst, authString, apiHost) => {
  try {
    const response = await fetch(`${apiHost}/generate-ics-file/${selectedChartSection['id']}.ics`, {
      headers: {
        Authorization: `Basic ${authString}`,
        cst,
      },
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const blob = await response.blob();
    const filename = `${selectedChartSection['id']}.ics`; // Replace with actual filename

    const link = document.createElement('a');
    // check for browser compatibility, older browsers (chrome/safari) might support window.navigator.webkitURL.createObjectURL()
    link.href = window.URL.createObjectURL(blob); // Use window.URL for broader compatibility
    link.download = filename;
    link.click();

    // Revoke object URL immediately to avoid memory leaks
    window.URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error('Error downloading file:', error);
    // Handle download error (e.g., display error message)
  }
};
