import { useEffect, useRef, useState } from 'react';
import style from '../../styles/filters/CountryFilter.module.css';
import { continents } from '../../utils/util';
import CountryList from './CountryList';
import { useEconCalendar } from '../../hooks/useEconCalendar';
import useScreenDimensions from '../../hooks/useScreenDimension';
import { useTranslation } from 'react-i18next';
import CustomCheckbox from './CustomCheckbox';

const CountryFilter = () => {
  const [selectedContinent, setSelectedContinent] = useState('Americas');
  const { t } = useTranslation();
  const {
    selectedContinents,
    updateContinentSelection,
    extendedCountryFilter,
    updateExtendedCountryFilter,
    showFilters,
    updateShowFilters,
    selectCountry,
    activeTab,
  } = useEconCalendar();

  const sectionRef = useRef(null);
  const importanceFilterPresent = ['events', 'earnings-reports'].includes(activeTab);
  const isMobile = useScreenDimensions();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedCountryIndex, setSelectedCountryIndex] = useState(null);
  const [activeSelected, setActiveSelected] = useState('continent');

  const continentList = Object.keys(continents);
  const countryList = Object.values(continents);

  const handleKeySelection = (continent) => {
    updateSelectedContinent(continent);
    updateSelection(continent);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const clickedElement = event.target;
      const countryFilterElement = clickedElement.closest(`.countryFilter`); // Select closest ancestor with class
      if (
        extendedCountryFilter &&
        sectionRef.current &&
        !sectionRef.current.contains(event.target) &&
        !countryFilterElement
      ) {
        updateExtendedCountryFilter();
      }
    };

    const handleKeyDown = (event) => {
      // if (!extendedCountryFilter) return;

      const { key } = event;
      const optionsLength = continentList.length;
      const optionsCountryLength = countryList[selectedIndex].length;

      if (key === 'ArrowDown') {
        activeSelected === 'continent' &&
          setSelectedIndex((prevIndex) => (prevIndex < optionsLength - 1 ? prevIndex + 1 : 0));
        activeSelected === 'country' &&
          setSelectedCountryIndex((prevIndex) =>
            prevIndex < optionsCountryLength - 1 ? prevIndex + 1 : 0
          );
      } else if (key === 'ArrowUp') {
        activeSelected === 'continent' &&
          setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : optionsLength - 1));
        activeSelected === 'country' &&
          setSelectedCountryIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : optionsCountryLength - 1
          );
      } else if (key === 'ArrowRight') {
        updateSelectedContinent(continentList[selectedIndex]);
        setActiveSelected('country');
        setSelectedCountryIndex(0);
      } else if (key === 'ArrowLeft') {
        setActiveSelected('continent');
        setSelectedIndex((prev) => prev);
        setSelectedCountryIndex(null);
      } else if (key === 'Escape') {
        updateExtendedCountryFilter();
      } else if (key === ' ' || key === 'Enter') {
        if (activeSelected === 'continent') {
          handleKeySelection(continentList[selectedIndex]);
        } else if (activeSelected === 'country') {
          selectCountry(countryList[selectedIndex][selectedCountryIndex]);
        }
      }
      event.preventDefault();
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousedown', handleClickOutside); // Listen for clicks outside the component

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [
    extendedCountryFilter,
    selectedIndex,
    selectedCountryIndex,
    activeSelected,
    updateExtendedCountryFilter,
    handleKeySelection,
    selectCountry,
  ]);

  useEffect(() => {
    activeSelected === 'continent' && updateSelectedContinent(continentList[selectedIndex]);
  }, [selectedIndex, selectedCountryIndex]);

  const updateSelectedContinent = (continent) => {
    setSelectedContinent(continent);
  };

  const updateSelection = (continent) => {
    if (!selectedContinents.includes(continent)) {
      updateContinentSelection(continent, 'select');
    } else {
      updateContinentSelection(continent, 'deselect');
    }
  };

  return (
    <div
      className={`${isMobile ? style.countryFilterPageMobile : style.expandedCountrySection} ${
        importanceFilterPresent ? style.importanceMargin : style.noMargin
      }`}
      ref={!isMobile ? sectionRef : null}
    >
      <div className={isMobile ? style.countryFilterPageContainer : style.expandedCountryContainer}>
        {showFilters && <div className={style.countryHeading}> {t('Country/Region')} </div>}
        {continentList.map((continent, index) => (
          <div
            className={`${isMobile ? style.continentSectionMobile : style.continentSection} ${
              selectedIndex === index ? style.applyBorder : ''
            }`}
            key={continent}
            data-testid={`continent_div_${continent}`}
          >
            <button
              className={`${isMobile ? style.continentMobile : style.continent} ${
                continent === selectedContinent && !isMobile ? style.active : ''
              }`}
              onClick={() => updateSelectedContinent(continent)}
              data-testid={continent}
            >
              <div>
                <CustomCheckbox
                  onClickHandler={updateSelection}
                  value={continent}
                  checked={selectedContinents.includes(continent)}
                  isContinent={true}
                />
              </div>
              <label className={!isMobile ? style.desktopLabel : ''}>{t(continent)}</label>
            </button>
            {isMobile && (
              <CountryList
                continent={continent}
                continents={continents}
                importanceFilterPresent={importanceFilterPresent}
              />
            )}
          </div>
        ))}
      </div>
      {!isMobile && (
        <CountryList
          continent={selectedContinent}
          continents={continents}
          importanceFilterPresent={importanceFilterPresent}
          selectedCountryIndex={selectedCountryIndex}
        />
      )}
      {isMobile && showFilters && (
        <div className={style.resultsButtonWrapper}>
          <button className={style.resultsButton} onClick={updateShowFilters}>
            {t('Go to the results')}
          </button>
        </div>
      )}
    </div>
  );
};

export default CountryFilter;
