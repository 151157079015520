import { useMemo } from 'react';
import { useEconCalendar } from '../hooks/useEconCalendar';
import TableRows from './TableRowsData/TableRows';
import style from '../styles/EconCalendarTableRows.module.css';
import { useTranslation } from 'react-i18next';
import {
  eventsTabKeys,
  eventsTabKeysMobile,
  dividendsTabKeys,
  ipoTabKeys,
  stockTabKeys,
  earningsTabKeys,
  checkName,
  headingsNameCheck,
  dataAccessProperty,
  earningsTabKeysMobile,
  dividendsTabKeysMobile,
  ipoTabKeysMobile,
  stockTabKeysMobile,
} from '../utils/tabsDataAccess-utils';
import useScreenDimensions from '../hooks/useScreenDimension';
import { useSessionData } from '../hooks/use-session-data';

export default function EconCalendarTableRows({ data, date }) {
  const { activeTab } = useEconCalendar();
  const isMobile = useScreenDimensions();
  const { timezoneOffset } = useSessionData();
  const { t } = useTranslation();

  const tabKeys = useMemo(() => {
    if (activeTab === 'events') {
      return isMobile ? eventsTabKeysMobile : eventsTabKeys;
    } else if (activeTab === 'dividends-reports') {
      return isMobile ? dividendsTabKeysMobile : dividendsTabKeys;
    } else if (activeTab === 'ipos') {
      return isMobile ? ipoTabKeysMobile : ipoTabKeys;
    } else if (activeTab === 'stock-splits') {
      return isMobile ? stockTabKeysMobile : stockTabKeys;
    } else if (activeTab === 'earnings-reports') {
      return isMobile ? earningsTabKeysMobile : earningsTabKeys;
    }
  }, [activeTab, isMobile]);

  return (
    <div className={isMobile ? style.dataWrapperMobile : style.dataWrapper}>
      {!isMobile && (
        <div className={`${activeTab}`} data-testid={activeTab}>
          {tabKeys &&
            tabKeys.length > 0 &&
            tabKeys.map((item, index) => {
              return (
                <span className={`${headingsNameCheck[item] || item} font`} key={item}>
                  {item === 'expand' || item === 'alert'
                    ? ''
                    : item === 'time' && activeTab === 'events'
                    ? `GMT${timezoneOffset > 0 ? '+' + timezoneOffset : timezoneOffset}`
                    : t(checkName[item] || item)}
                </span>
              );
            })}
        </div>
      )}
      <div className={style.tableRowWrapper}>
        {data &&
          Object.entries(data).map((key, value) => {
            const keyDate = key[0];
            const keyData = key[1];

            return (
              keyData &&
              keyData.length > 0 && (
                <div key={value}>
                  <div className={style.tableRowData}>
                    {keyDate.substring(0, 10) === date &&
                      keyData &&
                      keyData.length > 0 &&
                      keyData.map((singleDataEntry, ind) => {
                        const marketTrend = [
                          singleDataEntry?.actualValue - singleDataEntry.previousValue,
                          singleDataEntry?.revenueValue - singleDataEntry.revenuePreviousValue,
                        ];
                        return (
                          <TableRows
                            key={ind}
                            entryIndex={ind}
                            tabKeys={tabKeys}
                            headingsNameCheck={headingsNameCheck}
                            singleDataEntry={singleDataEntry}
                            marketTrend={marketTrend}
                            dataAccessProperty={dataAccessProperty}
                          />
                        );
                      })}
                  </div>
                </div>
              )
            );
          })}
      </div>
    </div>
  );
}
