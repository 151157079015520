import upArrow from '../../assets/svgs/earningsUpArrow.svg';
import downArrow from '../../assets/svgs/earningsDownArrow.svg';
import useScreenDimensions from '../../hooks/useScreenDimension';
import { formatToTwoDecimalPlaces } from '../../utils/util';
import { useTranslation } from 'react-i18next';

const EarningPerShare = ({ marketTrend, singleDataEntry }) => {
  const isMobile = useScreenDimensions();
  const { t } = useTranslation();
  return (
    <div className="earningspershare">
      {isMobile && <div className="eps"> ${t('EPS')}: </div>}
      <div className="actual">
        {!isMobile && <div className="label">{t('Actual')}</div>}

        <div
          className={
            singleDataEntry.actual
              ? marketTrend[0] > 0
                ? 'actualPositive'
                : 'actualNegative'
              : 'noMarketTrend'
          }
        >
          <span className="actualValue">
            {singleDataEntry?.actual ? formatToTwoDecimalPlaces(singleDataEntry.actual) : '-'}
          </span>
          {!isMobile &&
            singleDataEntry.actual &&
            (marketTrend[0] > 0 ? (
              <img src={upArrow} alt="value increased" loading="lazy" />
            ) : (
              <img src={downArrow} alt="value decreased" loading="lazy" />
            ))}
        </div>
      </div>
      <div className="forecast">
        {!isMobile && <span>{t('Forecast')}</span>}
        <span>
          {singleDataEntry?.forecast ? formatToTwoDecimalPlaces(singleDataEntry.forecast) : '-'}
        </span>
      </div>
      <div className="previous">
        {!isMobile && <span>{t('Previous')}</span>}
        <span>
          {singleDataEntry?.previous ? formatToTwoDecimalPlaces(singleDataEntry.previous) : '-'}
        </span>
      </div>
    </div>
  );
};

export default EarningPerShare;
