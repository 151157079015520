import { createContext, useContext } from 'react';

export const THEME_CLASSES = {
  DEFAULT: 'website',
  LIGHT: 'light',
  DARK: 'dark',
  WEBSITE: 'website',
  NAVY: 'navy',
};

type IEconCalendarParamsContext = {
  currentTheme: string;
  isWebsiteTheme: boolean;
  rowHeight: number;
  locale: string;
  baseColorSkeleton: string;
  highlightColorSkeleton: string;
};

type EconCalendarParamsProps = {
  children: React.ReactNode;
  theme: string;
  locale: string;
};

export const EconCalendarParamsContext = createContext<IEconCalendarParamsContext>({
  currentTheme: THEME_CLASSES.DEFAULT,
  isWebsiteTheme: false,
  rowHeight: 25,
  locale: 'en',
  baseColorSkeleton: '#e0e0e0',
  highlightColorSkeleton: '#f5f5f5',
});

export const EconCalendarParamsProvider: React.FC<EconCalendarParamsProps> = ({
  children,
  theme,
  locale,
}) => (
  <EconCalendarParamsContext.Provider
    value={{
      currentTheme: theme,
      isWebsiteTheme: theme === THEME_CLASSES.WEBSITE,
      rowHeight: theme === THEME_CLASSES.WEBSITE ? 57 : 25,
      locale,
      baseColorSkeleton: theme === 'dark' ? '#212B36' : theme === 'navy' ? '#0B1E44' : '#e0e0e0',
      highlightColorSkeleton:
        theme === 'dark' ? '#11171D' : theme === 'navy' ? '#0E1530' : '#f5f5f5',
    }}
  >
    {children}
  </EconCalendarParamsContext.Provider>
);

export const useEconCalendarParams = () => useContext(EconCalendarParamsContext);
