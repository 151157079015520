import { useState, useEffect, useRef } from 'react';
import { tabsTitles, useEconCalendar } from '../hooks/useEconCalendar';
import style from '../styles/EconCalendarTabs.module.css';
import { useTranslation } from 'react-i18next';
import DayPicker from './DayPicker';
import useScreenDimensions from '../hooks/useScreenDimension';
import { eventNameMappingForMobile } from '../utils/util';
import backArrow from '../assets/svgs/backArrow.svg';
import crossIcon from '../assets/svgs/cross.svg';
import dropDownIcon from '../assets/svgs/filtersArrowDown.svg';
import SearchBox from './Filters/SearchBox';
import ImportanceFilter from './Filters/ImportanceFilter';
import CountryFilter from './Filters/CountryFilter';
import EconCalendarFilters from './EconCalendarFilters';

const EconCalendarTabs = () => {
  const { t } = useTranslation();
  const {
    changeActiveTab,
    activeTab,
    showDetail,
    toggleDetail,
    expandTabs,
    updateExpandTabs,
    showSearch,
    showFilters,
    updateShowSearch,
    updateShowFilters,
  } = useEconCalendar();
  const isMobile = useScreenDimensions();
  const sectionRef = useRef(null);
  const [focusedButton, setFocusedButton] = useState(null);

  useEffect(() => {
    const handleClick = (event) => {
      if (sectionRef.current && !sectionRef.current.contains(event.target)) {
        updateExpandTabs(false);
      }
    };
    document.addEventListener('mousedown', handleClick);

    return () => document.removeEventListener('mousedown', handleClick);
  }, []);

  const updateTabs = (key, buttonElement) => {
    if (key !== activeTab) {
      changeActiveTab(key);
    }
    updateExpandTabs(null);
    setFocusedButton(key);
    if (buttonElement) {
      buttonElement.blur();
    }
  };

  const handleMouseDown = () => {
    setFocusedButton(null);
  };

  const handleButtonWhenFilter = () => (showFilters ? updateShowFilters() : toggleDetail(''));

  const handleButtonClick = () => (showSearch ? updateShowSearch() : handleButtonWhenFilter());

  const expandTabsClass = expandTabs ? style.tabSectionMobileExpanded : style.tabSectionMobile;
  const handleListClass = isMobile ? expandTabsClass : style.tabsSection;

  const handleKeyDown = (event, key) => {
    if (event.key === 'Enter' || event.key === ' ') {
      updateTabs(key, event.target);
      event.preventDefault();
    }
  };

  return (
    <div className={isMobile ? style.nav_filter_wrapper_mobile : style.nav_filter_wrapper}>
      <nav className={isMobile ? style.nav_wrapper_mobile : style.nav_wrapper}>
        {isMobile && (
          <button
            className={showFilters ? style.crossIcon : style.backArrow}
            onClick={handleButtonClick}
          >
            <img
              src={showFilters ? crossIcon : backArrow}
              alt={t('go back')}
              loading="lazy"
              data-testid="mobile-back-icon"
            />
          </button>
        )}
        {showFilters && <div className={style.filterHeading}> {t('Filters')} </div>}
        {showSearch && <SearchBox />}
        {!showSearch && !showFilters && (
          <div className={isMobile && expandTabs ? style.nav_wrapper_mobile_expanded : ''}>
            <ul ref={sectionRef} className={handleListClass}>
              {!isMobile || expandTabs ? (
                Object.entries(tabsTitles).map(([key, title]) => {
                  const isActive = activeTab === key;
                  return (
                    <li className={isActive ? style.active : style.inactive} key={key}>
                      <button
                        onMouseDown={handleMouseDown}
                        onClick={(e) => updateTabs(key, e.currentTarget)}
                        onKeyDown={(event) => handleKeyDown(event, key)}
                        className={focusedButton === key ? style.focusedButton : ''}
                        aria-pressed={isActive}
                        aria-label={t(title)}
                        data-testid={title.split(' ').join('-')}
                      >
                        <span> {t(title)} </span>
                      </button>
                    </li>
                  );
                })
              ) : (
                <li className={style.mobileActiveTab}>
                  <div className={style.activeTabSection}>
                    {showDetail ? (
                      <span className={style.details}> {t('Details')} </span>
                    ) : (
                      <button onClick={() => updateExpandTabs(null)} data-testid="update-tabs">
                        <span className={style.tabName}>
                          {t(eventNameMappingForMobile[activeTab])}
                        </span>
                        <img src={dropDownIcon} alt={t('drop down')} loading="lazy" />
                      </button>
                    )}
                  </div>
                </li>
              )}
            </ul>
          </div>
        )}
      </nav>
      {!showFilters && !showDetail && <DayPicker />}
      {isMobile && showFilters ? (
        <>
          <ImportanceFilter />
          <CountryFilter />
        </>
      ) : (
        !showDetail && <EconCalendarFilters />
      )}
    </div>
  );
};

export default EconCalendarTabs;
