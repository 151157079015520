import useScreenDimensions from '../../hooks/useScreenDimension';
import {
  formatPaymentDate,
  formatPaymentDateDividendsMobile,
  formatToTwoDecimalPlaces,
  getCountryName,
  getFormattedTime,
  importanceMapping,
  searchCountryCode,
} from '../../utils/util';
import Badge from '../Global/Badge';
import { countryCodeToNameMap } from '../../utils/countryCodeNameMapping';
import toEnableAlert from '../../assets/svgs/toEnableAlert.svg';
import { useEconCalendar } from '../../hooks/useEconCalendar';
import { FlagIconCode } from '../../types/flagIconCode';
import { FlagIcon } from 'react-flag-kit';
import ArrowExpandIcon from '../../assets/svgs/ArrowExpandIcon';
import EventCollapse from '../../assets/svgs/EventCollapse';
import { useSessionData } from '../../hooks/use-session-data';
import WorldFlag from '../../assets/svgs/WorldFlag';
import { supportedCountryCodes } from '../../utils/supportedFlagIconList';
import PlaceholderFlag from '../../assets/svgs/PlaceholderFlag';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useTranslation } from 'react-i18next';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

const Reports = ({ activeTab, tabKey, singleDataEntry, dataAccessProperty, isWorld }) => {
  const { t } = useTranslation();
  const isMobile = useScreenDimensions();
  const country = getCountryName(singleDataEntry, tabKey);
  const countryName =
    activeTab === 'dividends-reports'
      ? countryCodeToNameMap[singleDataEntry['symbol']?.split(':')[1]]
      : country;

  const dataValue =
    singleDataEntry[dataAccessProperty[`${activeTab}${tabKey}`]] || singleDataEntry[tabKey];
  const flagCode =
    activeTab === 'dividends-reports'
      ? singleDataEntry['symbol']?.split(':')[1]
      : (searchCountryCode(country) as FlagIconCode);

  const { selectedChartSection, isExpanded } = useEconCalendar();
  const { timezoneOffset } = useSessionData();
  const isHoliday = singleDataEntry.category === 'Holidays';

  const twoDecimalPlaceValue = ['actual', 'previous', 'forecast', 'amount'].includes(tabKey)
    ? formatToTwoDecimalPlaces(singleDataEntry[tabKey])
    : '';

  const {
    getTooltipProps: getTooltipProps,
    setTooltipRef: setTooltipRef,
    setTriggerRef: setTriggerRef,
    getArrowProps: getArrowProps,
    visible: visible,
  } = usePopperTooltip({ trigger: 'hover', placement: 'top' });

  const {
    getTooltipProps: getTooltipProps1,
    setTooltipRef: setTooltipRef1,
    setTriggerRef: setTriggerRef1,
    getArrowProps: getArrowProps1,
    visible: visible1,
  } = usePopperTooltip({ trigger: 'hover', placement: 'top' });

  const {
    getTooltipProps: getTooltipProps2,
    setTooltipRef: setTooltipRef2,
    setTriggerRef: setTriggerRef2,
    getArrowProps: getArrowProps2,
    visible: visible2,
  } = usePopperTooltip({ trigger: 'hover', placement: 'top' });

  const {
    getTooltipProps: getTooltipProps3,
    setTooltipRef: setTooltipRef3,
    setTriggerRef: setTriggerRef3,
    getArrowProps: getArrowProps3,
    visible: visible3,
  } = usePopperTooltip({ trigger: 'hover', placement: 'top' });
  const { locale } = useEconCalendarParams();

  return (
    <>
      {tabKey === 'expand' ? (
        !isHoliday &&
        (isExpanded && selectedChartSection.id === singleDataEntry.id ? (
          <EventCollapse />
        ) : (
          <ArrowExpandIcon />
        ))
      ) : tabKey === 'alert' ? (
        'Set Alert'
      ) : tabKey === 'time' && activeTab === 'events' && dataValue ? (
        !isHoliday && (
          <>
            {getFormattedTime(
              singleDataEntry[dataAccessProperty[`${activeTab}${tabKey}`]] ||
                singleDataEntry[tabKey],
              timezoneOffset
            )}
          </>
        )
      ) : tabKey === 'importance' && importanceMapping[singleDataEntry[tabKey]] ? (
        !isHoliday && <Badge text={importanceMapping[singleDataEntry[tabKey]]} />
      ) : tabKey === 'country' ? (
        <div className="countryDetails">
          <>
            <div className="flag" ref={setTriggerRef1}>
              {isWorld ? (
                <WorldFlag />
              ) : supportedCountryCodes.includes(flagCode) ? (
                <FlagIcon code={flagCode} loading="lazy" />
              ) : (
                <PlaceholderFlag />
              )}
            </div>
            {visible1 && (
              <div
                ref={setTooltipRef1}
                {...getTooltipProps1({ className: 'tooltip-container tooltip' })}
              >
                {t(countryName)}
                <div {...getArrowProps1({ className: 'tooltip-arrow' })} />
              </div>
            )}
          </>
          {!isMobile && (
            <>
              <div className={`countryName ${isHoliday ? 'holiday' : ''}`} ref={setTriggerRef2}>
                {t(countryName)}
              </div>

              {visible2 && (
                <div
                  ref={setTooltipRef2}
                  {...getTooltipProps2({ className: 'tooltip-container tooltip' })}
                >
                  {t(countryName)}
                  <div {...getArrowProps2({ className: 'tooltip-arrow' })} />
                </div>
              )}
            </>
          )}
        </div>
      ) : tabKey === 'company' && activeTab !== 'events' ? (
        <div className="companyDetails">
          <>
            <div className="name" ref={setTriggerRef3}>
              {singleDataEntry.name}
            </div>
            {visible3 && (
              <div
                ref={setTooltipRef3}
                {...getTooltipProps3({
                  className: `tooltip-container tooltip ${
                    singleDataEntry?.name.length > 66 ? 'tooltip-wrap' : 'tooltip-nowrap'
                  }`,
                })}
              >
                {singleDataEntry.name}
                <div {...getArrowProps3({ className: 'tooltip-arrow' })} />
              </div>
            )}
          </>
          <div className="symbol">
            {singleDataEntry?.symbol?.split(':')[0] || singleDataEntry?.ticker}
          </div>
        </div>
      ) : tabKey === 'event' && activeTab === 'events' ? (
        <div className="eventRowData" ref={setTriggerRef}>
          <div className={`data ${isHoliday ? 'holiday' : ''}`}>{dataValue}</div>
          {visible && (
            <div
              ref={setTooltipRef}
              {...getTooltipProps({
                className: `tooltip-container tooltip ${
                  dataValue?.length > 40 ? 'tooltip-wrap' : 'tooltip-nowrap'
                }`,
              })}
            >
              {dataValue}
              <div {...getArrowProps({ className: 'tooltip-arrow' })} />
            </div>
          )}
        </div>
      ) : tabKey === 'dividend' ? (
        <>
          <span className="exDividend">{t('Ex-dividend')}: </span>
          <span className="exDividendValue"> - </span>
        </>
      ) : tabKey === 'amount' && isMobile ? (
        <>
          <span className="amount">{t('Amount')}: </span>
          <span className="amountValue">
            {singleDataEntry[dataAccessProperty[`${activeTab}${tabKey}`]] ||
              singleDataEntry[tabKey]}
          </span>
        </>
      ) : tabKey === 'payment day' ? (
        !isMobile && singleDataEntry[dataAccessProperty[`${activeTab}${tabKey}`]] ? (
          formatPaymentDate(
            singleDataEntry[dataAccessProperty[`${activeTab}${tabKey}`]],
            locale != 'en' ? locale : undefined
          )
        ) : (
          <>
            <span className="payment">{t('Payment')}:</span>
            <span className="paymentValue">
              {formatPaymentDateDividendsMobile(
                singleDataEntry[dataAccessProperty[`${activeTab}${tabKey}`]]
              )}
            </span>
          </>
        )
      ) : tabKey === 'exchange' && isMobile ? (
        <>
          <span className="exchangeLabel">{t('Exchange')}: </span>
          <span className="exchangeValue">
            {singleDataEntry[dataAccessProperty[`${activeTab}${tabKey}`]] ||
              singleDataEntry[tabKey] ||
              '-'}
          </span>
        </>
      ) : tabKey === 'startPrice' && isMobile ? (
        <>
          <span className="startPriceLabel">{t('Price')}:</span>
          <span className="startPriceValue">
            {singleDataEntry[dataAccessProperty[`${activeTab}${tabKey}`]] ||
              singleDataEntry[tabKey] ||
              '-'}
            {singleDataEntry['currency']}
          </span>
        </>
      ) : tabKey === 'value' && isMobile ? (
        <>
          <span className="valueLabel">{t('Value')}: </span>
          <span className="valueQuantity">
            {singleDataEntry[dataAccessProperty[`${activeTab}${tabKey}`]] ||
              singleDataEntry[tabKey] ||
              '-'}
            {singleDataEntry['currency']}
          </span>
        </>
      ) : tabKey === 'date' && isMobile ? (
        <>
          <span className="dateLabel">{t('Date')}: </span>
          <span className="startPriceValue">
            {singleDataEntry[dataAccessProperty[`${activeTab}${tabKey}`]] ||
              singleDataEntry[tabKey] ||
              '-'}
          </span>
        </>
      ) : tabKey === 'date' && isMobile ? (
        <>
          <span className="dateLabel">{t('Date')}:</span>
          <span className="dateValue">
            {singleDataEntry[dataAccessProperty[`${activeTab}${tabKey}`]] ||
              (singleDataEntry[tabKey] &&
                getFormattedTime(
                  singleDataEntry[dataAccessProperty[`${activeTab}${tabKey}`]] ||
                    singleDataEntry[tabKey],
                  timezoneOffset
                )) ||
              '-'}
          </span>
        </>
      ) : tabKey === 'bell' ? (
        <img src={toEnableAlert} alt="alert icon" loading="lazy" />
      ) : tabKey === 'period' ? (
        <> {t('Period')}: - </>
      ) : isMobile && tabKey === 'forecast' ? (
        <>
          {' '}
          {t('Forecast')}: {singleDataEntry[tabKey] === '' ? '-' : singleDataEntry[tabKey]}
        </>
      ) : isMobile && tabKey === 'actual' ? (
        <>
          {' '}
          {t('Actual')}: {singleDataEntry[tabKey] === '' ? '-' : singleDataEntry[tabKey]}{' '}
        </>
      ) : isMobile && tabKey === 'previous' ? (
        <>
          {' '}
          {t('Previous')}: {singleDataEntry[tabKey] === '' ? '-' : singleDataEntry[tabKey]}{' '}
        </>
      ) : twoDecimalPlaceValue !== '' ? (
        twoDecimalPlaceValue
      ) : (
        dataValue || (!isHoliday && '-')
      )}
    </>
  );
};

export default Reports;
