import { useEconCalendarParams } from '../hooks/use-econ-calendar-params';
import { useEconCalendar } from '../hooks/useEconCalendar';
import style from '../styles/EventsBasedOnImportance.module.css';
import { getCountryCode, getCountryName, importanceMapping } from '../utils/util';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const getImportanceCount = (
  econData: {},
  dateFilterOption: string,
  allCountry: string[],
  selectedImportance: string[],
  activeTab: string,
  searchText: string
) => {
  const importanceCount = {
    high: 0,
    medium: 0,
    low: 0,
  };

  const selectedFilterData = econData[dateFilterOption];
  selectedFilterData &&
    Object.keys(selectedFilterData).forEach((data) => {
      const dateData = selectedFilterData[data];
      dateData.length &&
        dateData?.forEach((value) => {
          let countryCode;
          if (activeTab === 'dividends-reports') {
            countryCode = value?.symbol?.split(':')[1];
          } else {
            value.country = getCountryName(value, 'country');
            countryCode = getCountryCode(value.country);
            countryCode = countryCode && countryCode.length > 0 && countryCode[0][0];
          }
          if (
            value.importance >= 0 &&
            value.category !== 'Holidays' &&
            countryCode.length &&
            allCountry.includes(countryCode) &&
            selectedImportance.includes(importanceMapping[value.importance])
          ) {
            if (searchText.length > 0) {
              if (
                value.event?.toLowerCase().includes(searchText.toLowerCase()) ||
                value.name?.toLowerCase().includes(searchText.toLowerCase())
              ) {
                importanceCount[importanceMapping[value.importance]] =
                  importanceCount[importanceMapping[value.importance]] + 1;
              }
            } else {
              importanceCount[importanceMapping[value.importance]] =
                importanceCount[importanceMapping[value.importance]] + 1;
            }
          }
        });
    });
  return importanceCount;
};

const EventsBasedOnImportance = ({ activeDate, dateFilterOption }) => {
  const { econData, isDataLoading, allCountry, selectedImportance, activeTab, searchText } =
    useEconCalendar();
  const importanceCount = getImportanceCount(
    econData,
    dateFilterOption,
    allCountry,
    selectedImportance,
    activeTab,
    searchText
  );
  const { baseColorSkeleton, highlightColorSkeleton } = useEconCalendarParams();

  return (
    <div>
      {!isDataLoading || (econData && Object.values(econData).length > 0) ? (
        <div className={`${style.eventNumberOnImportance} ${activeDate ? style.activeDate : ''}`}>
          <span className={`${style.highImportance} ${style.fontStyling}`}>
            {importanceCount['high']}
          </span>
          <span className={`${style.mediumImportance} ${style.fontStyling}`}>
            {importanceCount['medium']}
          </span>
          <span className={`${style.lowImportance} ${style.fontStyling}`}>
            {importanceCount['low']}
          </span>
        </div>
      ) : (
        <SkeletonTheme baseColor={baseColorSkeleton} highlightColor={highlightColorSkeleton}>
          <div>
            <Skeleton height={10} />
          </div>
        </SkeletonTheme>
      )}
    </div>
  );
};

export default EventsBasedOnImportance;
