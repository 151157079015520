// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fU7IMDQ8YNaBOY_SmBD1 {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 4px;
  height: 16px;
}

.vxpMqTtxmC6iTQSiaAdJ {
  background-color: var(--active-date-filter-background-color);
}

.R8r304lh0AeI5ciTfLnJ {
  background-color: var(--high-importance-bg-color);
  color: var(--high-importance-text-color);
}

.si9g2BOHe8itsMe6bVQq {
  background-color: var(--medium-importance-bg-color);
  color: var(--medium-importance-text-color);
}

.JBEkPNgLkpHRjMSd7q_A {
  background-color: var(--low-importance-bg-color);
  color: var(--primary-text-color);
}

.YSzE8gKo6jT1tmUEAz54 {
  /* width: max(fit-content, 16px); */
  min-width: 16px;
  padding: 0 2px;
  height: 16px;
  border-radius: 2px;
  font-weight: 500;
  text-align: center;
  font-size: 10px;
  font-family: 'MatterIGTF-Medium';
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/styles/EventsBasedOnImportance.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,YAAY;AACd;;AAEA;EACE,4DAA4D;AAC9D;;AAEA;EACE,iDAAiD;EACjD,wCAAwC;AAC1C;;AAEA;EACE,mDAAmD;EACnD,0CAA0C;AAC5C;;AAEA;EACE,gDAAgD;EAChD,gCAAgC;AAClC;;AAEA;EACE,mCAAmC;EACnC,eAAe;EACf,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,gCAAgC;EAChC,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".eventNumberOnImportance {\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  height: 16px;\n}\n\n.activeDate {\n  background-color: var(--active-date-filter-background-color);\n}\n\n.highImportance {\n  background-color: var(--high-importance-bg-color);\n  color: var(--high-importance-text-color);\n}\n\n.mediumImportance {\n  background-color: var(--medium-importance-bg-color);\n  color: var(--medium-importance-text-color);\n}\n\n.lowImportance {\n  background-color: var(--low-importance-bg-color);\n  color: var(--primary-text-color);\n}\n\n.fontStyling {\n  /* width: max(fit-content, 16px); */\n  min-width: 16px;\n  padding: 0 2px;\n  height: 16px;\n  border-radius: 2px;\n  font-weight: 500;\n  text-align: center;\n  font-size: 10px;\n  font-family: 'MatterIGTF-Medium';\n  line-height: 12px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eventNumberOnImportance": `fU7IMDQ8YNaBOY_SmBD1`,
	"activeDate": `vxpMqTtxmC6iTQSiaAdJ`,
	"highImportance": `R8r304lh0AeI5ciTfLnJ`,
	"mediumImportance": `si9g2BOHe8itsMe6bVQq`,
	"lowImportance": `JBEkPNgLkpHRjMSd7q_A`,
	"fontStyling": `YSzE8gKo6jT1tmUEAz54`
};
export default ___CSS_LOADER_EXPORT___;
