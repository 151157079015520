import { useEconCalendar } from '../../hooks/useEconCalendar';
import style from '../../styles/filters/CustomCheckbox.module.css';
import { continents } from '../../utils/util';

const CustomCheckbox = ({ onClickHandler, value, checked, isContinent = false }) => {
  const { allCountry } = useEconCalendar();
  const countries = isContinent && continents[value];
  return (
    <div className={`${style.checkbox_custom_wrapper}`}>
      <button
        onClick={() => onClickHandler(value)}
        className={`${style.checkbox_custom}  ${checked ? style.noShadow : style.shadow} ${
          checked
            ? isContinent
              ? countries.every((value) => allCountry.includes(value))
                ? style.checkedAll
                : style.checkedPartial
              : style.checkedAll
            : style.unchecked
        }`}
        data-testid={`checkbox_${value}`}
      ></button>
    </div>
  );
};

export default CustomCheckbox;
