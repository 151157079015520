import { useEconCalendar } from '../../hooks/useEconCalendar';
import useScreenDimensions from '../../hooks/useScreenDimension';
import style from '../../styles/Badge.module.css';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useTranslation } from 'react-i18next';

export default function Badge({ text }) {
  const { t } = useTranslation();
  const badgeStyle =
    text.toLowerCase() === 'high'
      ? style.highImportance
      : text.toLowerCase() === 'medium'
      ? style.mediumImportance
      : style.lowImportance;
  const isMobile = useScreenDimensions();
  const { showDetail } = useEconCalendar();

  const {
    getTooltipProps: getTooltipProps,
    setTooltipRef: setTooltipRef,
    setTriggerRef: setTriggerRef,
    getArrowProps: getArrowProps,
    visible: visible,
  } = usePopperTooltip({ trigger: 'hover', placement: 'top' });
  const valueToShow = t(isMobile && !showDetail ? text[0] : text);
  return (
    <div
      className={`${
        isMobile && !showDetail ? style.badgeWrapperMobile : style.badgeWrapper
      } ${badgeStyle}`}
      ref={!isMobile ? setTriggerRef : null}
    >
      <div className={isMobile && !showDetail ? style.mobileFont : style.font}>{valueToShow}</div>
      {!isMobile && visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: `tooltip-container tooltip` })}>
          {valueToShow}
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
    </div>
  );
}
