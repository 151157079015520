export const eventsTabKeys = [
  'time',
  'country',
  'importance',
  'event',
  'actual',
  'forecast',
  'previous',
  'alert',
  'expand',
];

export const eventsTabKeysMobile = [
  [['country', 'importance', 'event'], ['time']],
  [
    [
      ['period', 'forecast'],
      ['actual', 'previous'],
    ],
    ['bell'],
  ],
];

export const earningsTabKeysMobile = [
  [['country', 'importance', 'company'], ['time']],
  [['earning per share', 'revenue']],
];

export const dividendsTabKeys = ['country', 'company', 'payment day', 'amount'];

export const dividendsTabKeysMobile = [
  [['country'], ['company']],
  [['dividend', 'payment day'], ['amount']],
];

export const ipoTabKeysMobile = [
  [['country'], ['company']],
  [
    ['exchange', 'startPrice'],
    ['value', 'date'],
  ],
];

export const stockTabKeysMobile = [
  [['country', 'company'], ['ratio']],
  [['declared'], ['distribution']],
];

export const ipoTabKeys = ['country', 'company', 'exchange', 'startPrice', 'value'];
export const stockTabKeys = ['country', 'company', 'ratio'];
export const earningsTabKeys = [
  'time',
  'country',
  'importance',
  'company',
  'earning per share',
  'revenue',
];

export const checkName = {
  startPrice: 'Start Price',
  ratio: 'Ratio',
  paymentDate: 'Payment Date',
  company: 'Company Name',
  previous: 'Previous',
  revenuePrevious: 'Revenue',
};

export const headingsNameCheck = {
  'payment day': 'paymentday',
  'earning per share': 'earningspershare',
};

export const dataAccessProperty = {
  eventstime: 'date',
  'dividends-reportscompany': 'name',
  iposcompany: 'name',
  'dividends-reportspayment day': 'paymentDate',
};
