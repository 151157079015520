import { useRef } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useEconCalendar } from '../../hooks/useEconCalendar';
import HistoricalChart from '../HistoricalChart';
import TabRowDataIndividiualRow from './TabRowDataIndividiualRow';
import { downloadIcsFile } from '../../api/EconCalendarApi';
import style from '../../styles/TableRows.module.css';
import '../../styles/TableRows.scss';
import useScreenDimensions from '../../hooks/useScreenDimension';
import { shouldShowAddToCalendar } from '../../utils/util';
import { useTranslation } from 'react-i18next';
import { useSessionData } from '../../hooks/use-session-data';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

export default function TableRows({
  singleDataEntry,
  tabKeys,
  entryIndex,
  headingsNameCheck,
  marketTrend,
  dataAccessProperty,
}) {
  const buttonRef = useRef(null);
  const firstDivRef = useRef(null);

  const {
    activeTab,
    chartData,
    selectedChartSection,
    isExpanded,
    isChartLoading,
    fetchHistoricalData,
    toggleDetail,
    updatedData,
    authString,
  } = useEconCalendar();

  const { baseColorSkeleton, highlightColorSkeleton } = useEconCalendarParams();

  const isMobile = useScreenDimensions();
  const { t } = useTranslation();
  const { cst, tastyApiUrl, timezoneOffset } = useSessionData();

  const handleExpand = (selectedData) => {
    fetchHistoricalData(selectedData);
  };

  const isHoliday = singleDataEntry['category'] === 'Holidays';

  const handleClick = (event) => {
    const isEnterKey = event.key === 'Enter';
    const isDivClicked = firstDivRef.current.contains(event.target);

    if (isEnterKey || isDivClicked) {
      if (activeTab === 'events') {
        if (!isMobile) {
          !isHoliday && handleExpand(singleDataEntry);
        } else {
          toggleDetail(singleDataEntry);
          fetchHistoricalData(singleDataEntry);
        }
      }
      buttonRef.current.blur(); // Remove focus when clicked
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default behavior (like form submission)
      handleClick(event); // Call handleClick when Enter is pressed
    }
  };

  const checkUpdateEligibility =
    singleDataEntry.id in updatedData &&
    new Date(updatedData[singleDataEntry.id].lastUpdate) > new Date(singleDataEntry.lastUpdate);

  if (checkUpdateEligibility) {
    if (updatedData[singleDataEntry.id].event === '')
      updatedData[singleDataEntry.id].event = singleDataEntry.event;
    if (!updatedData[singleDataEntry.id].description)
      updatedData[singleDataEntry.id].description = singleDataEntry.description;
    if (!updatedData[singleDataEntry.id].category) {
      updatedData[singleDataEntry.id].category = singleDataEntry.category;
    }
    singleDataEntry = checkUpdateEligibility ? updatedData[singleDataEntry.id] : singleDataEntry;
  }

  return (
    <button
      ref={buttonRef}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      className={isMobile ? style.tableRowsMobile : style.tableRows}
      aria-expanded={selectedChartSection?.id === singleDataEntry?.id}
      aria-label={singleDataEntry.ticker}
    >
      <div
        ref={firstDivRef}
        className={`${isMobile ? activeTab + 'RowMobile' : activeTab + 'Row'} ${
          checkUpdateEligibility && 'updatedValues'
        } ${isHoliday && 'holiday'}`}
        data-testid={`${isMobile ? activeTab + 'RowMobile' : activeTab + 'Row'}`}
      >
        {tabKeys &&
          tabKeys.length > 0 &&
          tabKeys.map((tabKey, index) => {
            if (Array.isArray(tabKey)) {
              return (
                <div className={'div' + index} key={index}>
                  {tabKey.map((tabValue, indexValue) => {
                    if (Array.isArray(tabValue)) {
                      return (
                        <div className={'column' + indexValue} key={indexValue}>
                          {tabValue.map((value, indexValue2) => {
                            if (Array.isArray(value)) {
                              return (
                                <div className={'section' + indexValue2} key={indexValue2}>
                                  {value.map((property, valueIndex) => {
                                    return (
                                      <TabRowDataIndividiualRow
                                        key={`${entryIndex}${value}`}
                                        activeTab={activeTab}
                                        tabKey={property}
                                        index={valueIndex}
                                        headingsNameCheck={headingsNameCheck}
                                        singleDataEntry={singleDataEntry}
                                        marketTrend={marketTrend}
                                        dataAccessProperty={dataAccessProperty}
                                      />
                                    );
                                  })}
                                </div>
                              );
                            } else {
                              return (
                                <TabRowDataIndividiualRow
                                  key={`${indexValue2}${value}`}
                                  activeTab={activeTab}
                                  tabKey={value}
                                  index={indexValue2}
                                  headingsNameCheck={headingsNameCheck}
                                  singleDataEntry={singleDataEntry}
                                  marketTrend={marketTrend}
                                  dataAccessProperty={dataAccessProperty}
                                />
                              );
                            }
                          })}
                        </div>
                      );
                    } else {
                      return (
                        <div className={tabValue} key={`${indexValue}_${tabValue}`}>
                          <TabRowDataIndividiualRow
                            key={`${entryIndex}${tabKey}`}
                            activeTab={activeTab}
                            tabKey={tabKey}
                            index={indexValue}
                            headingsNameCheck={headingsNameCheck}
                            singleDataEntry={singleDataEntry}
                            marketTrend={marketTrend}
                            dataAccessProperty={dataAccessProperty}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              );
            } else {
              return (
                <TabRowDataIndividiualRow
                  key={`${entryIndex}${tabKey}`}
                  activeTab={activeTab}
                  tabKey={tabKey}
                  index={index}
                  headingsNameCheck={headingsNameCheck}
                  singleDataEntry={singleDataEntry}
                  marketTrend={marketTrend}
                  dataAccessProperty={dataAccessProperty}
                />
              );
            }
          })}
      </div>
      {!isMobile &&
        isExpanded &&
        activeTab === 'events' &&
        selectedChartSection.id === singleDataEntry.id && (
          <div className={style.expandSection}>
            <div
              className={`${style.onlyDescription} ${
                chartData && chartData.length > 0 && style.chartAndDescription
              } ${
                !(singleDataEntry.description && chartData && chartData.length > 0) &&
                style.noInfoWrapper
              }`}
            >
              {chartData &&
                chartData.length > 0 &&
                (!isChartLoading ? (
                  <div className={style.chartWrapper}>
                    <HistoricalChart data={chartData} width={478} height={207} />
                  </div>
                ) : (
                  <SkeletonTheme
                    baseColor={baseColorSkeleton}
                    highlightColor={highlightColorSkeleton}
                  >
                    <Skeleton width={478} height={207} />
                  </SkeletonTheme>
                ))}
              {chartData.length > 0 && singleDataEntry.description && (
                <div className={style.separator}> </div>
              )}
              {singleDataEntry.description && (
                <div className={`${style.description}`}>
                  <h4> {t('Summary')} </h4>
                  <p
                    className={
                      chartData.length || singleDataEntry.description.length > 600
                        ? style.biggerPara
                        : style.smallerPara
                    }
                  >
                    {selectedChartSection?.description}
                  </p>
                </div>
              )}
              {!isChartLoading && !chartData.length && !singleDataEntry.description && (
                <div className={style.noInformation}> {t('No information available')} </div>
              )}
            </div>
            {shouldShowAddToCalendar(singleDataEntry.date, timezoneOffset) && (
              <div className={`${style.addToCalendar}`}>
                <button
                  onClick={() =>
                    downloadIcsFile(selectedChartSection, cst, authString, tastyApiUrl)
                  }
                  data-testid="add-to-calendar"
                >
                  {t('Add to calendar')}
                </button>
              </div>
            )}
          </div>
        )}
    </button>
  );
}
