import { useEffect, useRef, useState } from 'react';
import { useEconCalendar } from '../hooks/useEconCalendar';
import style from '../styles/DayPicker.module.css';
import EventsBasedOnImportance from './EventsBasedOnImportance';
import useScreenDimensions from '../hooks/useScreenDimension';
import { useTranslation } from 'react-i18next';
import { useEconCalendarParams } from '../hooks/use-econ-calendar-params';
import CalendarIcon from '../assets/svgs/CalendarIcon';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const pickOptions = [
  'yesterday',
  'today',
  'tomorrow',
  'next 7 days',
  'next 14 days',
  'custom date',
];

const DayPicker = () => {
  const { activeDateFilter, changeDateFilter, activeTab } = useEconCalendar();
  const isImportanceValid = activeTab === 'events' || activeTab === 'earnings-reports';
  const isMobile = useScreenDimensions();
  const { t } = useTranslation();
  const { currentTheme } = useEconCalendarParams();
  const calendarBackgroundStyle = currentTheme === 'dark' ? style.darkTheme : style.whiteTheme;
  const [focusedButton, setFocusedButton] = useState(null);
  const [date, setDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef(null);
  const today = new Date();
  const maxDate = new Date(today.setMonth(today.getMonth() + 3));

  const handleButtonClick = (event, value, buttonElement) => {
    if (value !== 'custom date' && activeDateFilter[0] !== value) {
      changeDateFilter(value, '');
      setShowCalendar(false);
    }

    if (value === 'custom date') {
      event.stopPropagation();
      setShowCalendar((prev) => !prev);
    }

    setFocusedButton(value);
    if (buttonElement) {
      buttonElement.blur();
    }
  };

  const handleKeyDown = (event, value) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleButtonClick(event, value, event.target);
      event.target.blur();
      event.preventDefault();
    }
  };

  const handleClickOutside = (event) => {
    if (
      calendarRef.current &&
      !calendarRef.current.contains(event.target) &&
      !event.target.closest('button')
    ) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    if (showCalendar) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showCalendar]);

  const onChange = (newDate) => {
    setDate(newDate);
    setShowCalendar(false);
    changeDateFilter('custom date', newDate);
  };

  return (
    <div className={`${style.line} ${isMobile ? style.dayPickerMobile : style.dayPickerDesktop}`}>
      <div className={isMobile ? style.dayPickerContainerMobile : style.dayPickerContainer}>
        {pickOptions.map((value) => {
          const isActive = activeDateFilter[0] === value;
          return (
            <button
              className={`${isMobile ? style.mobileOption : style.option}
              ${
                isActive ? (!isMobile ? style.activeDate : style.activeDateMobile) : style.inactive
              } ${focusedButton === value ? style.focusedButton : ''}`}
              key={value}
              data-testid={value}
              onClick={(e) => handleButtonClick(e, value, e.currentTarget)}
              onKeyDown={(event) => handleKeyDown(event, value)}
              aria-pressed={isActive}
              aria-label={t(value)}
              onBlur={() => setFocusedButton(null)}
            >
              <div> {t(value)} </div>
              {value === 'custom date' ? (
                <div className={style.dateWrapper}>
                  <CalendarIcon />
                  {showCalendar && (
                    <div
                      onClick={(e) => e.stopPropagation()}
                      ref={calendarRef}
                      role="button"
                      tabIndex={0}
                      onKeyDown={(event) => handleKeyDown(event, value)}
                      aria-label={t('Select custom date')}
                    >
                      <Calendar
                        className={`${style.calendar} ${calendarBackgroundStyle}`}
                        onChange={onChange}
                        value={date}
                        maxDate={maxDate}
                      />
                    </div>
                  )}
                </div>
              ) : (
                isImportanceValid &&
                !isMobile && (
                  <EventsBasedOnImportance
                    activeDate={isActive}
                    dateFilterOption={value}
                    aria-hidden={!isActive}
                  />
                )
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default DayPicker;
