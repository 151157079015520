export default function SuccessIcon({ classes }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <rect width="32" height="32" rx="16" fill="#BED8EF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.52662 15.4824L13.6495 21.7106L22.4735 8.38059C22.6157 8.16542 22.8408 8.02747 23.0885 8.00367C23.3362 7.97987 23.5812 8.07264 23.758 8.25715C24.051 8.58227 24.0814 9.08192 23.8302 9.44385L14.5064 23.529C14.3108 23.8241 13.9909 24 13.6494 24C13.308 24 12.9881 23.8241 12.7925 23.529L8.16986 16.5457C7.91858 16.1837 7.94896 15.684 8.24201 15.3588C8.4188 15.1743 8.66385 15.0815 8.91157 15.1054C9.15928 15.1292 9.38438 15.2672 9.52662 15.4824Z"
        fill="#1D5890"
        stroke="#1D5890"
      />
    </svg>
  );
}
