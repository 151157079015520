// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IFju9x3mfZTQ4ks8AMFp {
  width: 100%;
  padding: 1px 12px;
  background-color: #fff;
  border-bottom: 0.5px solid #e2eced;
  height: 20px;
  display: flex;
  gap: 6px;
  position: sticky;
  top: 48px;
  z-index: 36;
}

.h8fYFPG0wl1EcG3_PyJq {
  width: 25%;
}

.KDGJCUlqEc6gcxhausmr {
  display: flex;
  width: 75%;
  gap: 6px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.KDGJCUlqEc6gcxhausmr > span {
  width: 33%;
  text-align: left;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: var(--secondary-text-color);
}
`, "",{"version":3,"sources":["webpack://./src/styles/EarningsMobileHeadings.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,sBAAsB;EACtB,kCAAkC;EAClC,YAAY;EACZ,aAAa;EACb,QAAQ;EACR,gBAAgB;EAChB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,UAAU;EACV,QAAQ;EACR,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,kCAAkC;AACpC","sourcesContent":[".headingsWrapper {\n  width: 100%;\n  padding: 1px 12px;\n  background-color: #fff;\n  border-bottom: 0.5px solid #e2eced;\n  height: 20px;\n  display: flex;\n  gap: 6px;\n  position: sticky;\n  top: 48px;\n  z-index: 36;\n}\n\n.emptyBox {\n  width: 25%;\n}\n\n.headings {\n  display: flex;\n  width: 75%;\n  gap: 6px;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n\n.headings > span {\n  width: 33%;\n  text-align: left;\n  font-size: 12px;\n  line-height: 18px;\n  font-weight: 400;\n  color: var(--secondary-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headingsWrapper": `IFju9x3mfZTQ4ks8AMFp`,
	"emptyBox": `h8fYFPG0wl1EcG3_PyJq`,
	"headings": `KDGJCUlqEc6gcxhausmr`
};
export default ___CSS_LOADER_EXPORT___;
