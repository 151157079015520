import { useEffect, useState } from 'react';
import { useEconCalendar } from '../../hooks/useEconCalendar';
import style from '../../styles/filters/SearchBox.module.css';
import useScreenDimensions from '../../hooks/useScreenDimension';
import { useTranslation } from 'react-i18next';
import SearchIcon from '../IconComponents/SearchIcon';
import CrossIconDesktop from '../IconComponents/CrossIconDesktop';
import CrossIconMobile from '../IconComponents/CrossIconMobile';

const SearchBox = () => {
  const [searchTextInput, setSearchTextInput] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] = useState('');
  const { activeTab, updateSearchText } = useEconCalendar();
  const isMobile = useScreenDimensions();
  const { t } = useTranslation();

  const search_placeholder = {
    events: 'Search for events',
    'earnings-reports': 'Search for earning announcements',
    'dividends-reports': 'Search for dividend announcements',
    ipos: 'Search for upcoming IPOs',
    'stock-splits': 'Search for upcoming stock splits',
  };

  const updateInput = (e) => {
    e.preventDefault();
    setSearchTextInput(e.target.value);
  };

  useEffect(() => {
    updateSearchText(debouncedInputValue.trim());
  }, [debouncedInputValue]);

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(searchTextInput);
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [searchTextInput]);

  const clearText = () => {
    setSearchTextInput('');
  };

  return (
    <div className={isMobile ? style.searchBoxWrapperMobile : style.searchBoxWrapper}>
      <div className={isMobile ? style.formMobile : style.form}>
        {!isMobile && <SearchIcon />}
        <input
          type="text"
          placeholder={isMobile ? t('Search') : t(search_placeholder[activeTab])}
          onChange={(e) => updateInput(e)}
          maxLength={50}
          data-testid="search_input"
          value={searchTextInput}
          aria-label="Search" // Added aria-label for accessibility
        />
        {!isMobile && searchTextInput.length > 0 && (
          <button
            className={style.clearTextDesktopButtonShow}
            onClick={clearText}
            data-testid="clear-searchbox"
          >
            <CrossIconDesktop />
          </button>
        )}
        {isMobile && (
          <button onClick={clearText}>
            <CrossIconMobile />
          </button>
        )}
      </div>
    </div>
  );
};

export default SearchBox;
