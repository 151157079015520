import { downloadIcsFile } from '../api/EconCalendarApi';
import { useEconCalendarParams } from '../hooks/use-econ-calendar-params';
import { useEconCalendar } from '../hooks/useEconCalendar';
import style from '../styles/Detail.module.css';
import {
  convertDateToString,
  getCountryName,
  getFormattedTime,
  importanceMapping,
  searchCountryCode,
  shouldShowAddToCalendar,
} from '../utils/util';
import Badge from './Global/Badge';
import HistoricalChart from './HistoricalChart';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { FlagIcon } from 'react-flag-kit';
import { FlagIconCode } from '../types/flagIconCode';
import WorldFlag from '../assets/svgs/WorldFlag';
import { supportedCountryCodes } from '../utils/supportedFlagIconList';
import PlaceholderFlag from '../assets/svgs/PlaceholderFlag';
import { useSessionData } from '../hooks/use-session-data';

const MobileDetailsPage = () => {
  const { eventDetailValue, chartData, isChartLoading, selectedChartSection, authString } =
    useEconCalendar();
  const countryName = getCountryName(eventDetailValue, 'country');
  const flagCode = searchCountryCode(countryName) as FlagIconCode;
  const isWorld = eventDetailValue['country']?.toUpperCase() === 'WORLD';
  const { locale, baseColorSkeleton, highlightColorSkeleton } = useEconCalendarParams();
  const { t } = useTranslation();
  const { cst, tastyApiUrl } = useSessionData();
  const timezoneOffset = 0;

  return (
    <div className={style.detailWrapper}>
      <div className={style.firstRow}>
        <Badge text={importanceMapping[eventDetailValue['importance']]} />
        <span className={style.timeInfo}>
          {getFormattedTime(eventDetailValue['date'], timezoneOffset)},
          {convertDateToString(
            eventDetailValue['date'],
            timezoneOffset,
            locale != 'en' ? locale : undefined,
            t
          )}
        </span>
      </div>
      <div className={style.secondRow}>{eventDetailValue['event']}</div>
      <div className={style.thirdRow}>
        {isWorld ? (
          <WorldFlag />
        ) : supportedCountryCodes.includes(flagCode) ? (
          <FlagIcon code={flagCode} loading="lazy" />
        ) : (
          <PlaceholderFlag />
        )}
        <span className={style.countryName}>{t(countryName)}</span>
      </div>
      <div className={style.fourthRow}>
        <div>
          <div className={style.period}>
            <div> {t('Period')} </div>
            <div> {eventDetailValue['period'] || '-'} </div>
          </div>
          <div className={style.actual}>
            <div> {t('Actual')} </div>
            <div> {eventDetailValue['actual'] || '-'} </div>
          </div>
        </div>
        <div>
          <div className={style.forecast}>
            <div> {t('Forecast')} </div>
            <div> {eventDetailValue['forecast'] || '-'} </div>
          </div>
          <div className={style.previous}>
            <div> {t('Previous')} </div>
            <div> {eventDetailValue['previous'] || '-'} </div>
          </div>
        </div>
      </div>
      <div className={style.buttons}>
        <button> {t('CANCEL ALERT')} </button>
        {shouldShowAddToCalendar(eventDetailValue['date']) && (
          <button
            onClick={() => downloadIcsFile(selectedChartSection, cst, authString, tastyApiUrl)}
          >
            {t('ADD TO CALENDAR')}
          </button>
        )}
      </div>

      <div className={style.historicalData}>
        <div> {t('Historical data')} </div>
        <div className={style.chartDescription}>
          {chartData &&
            chartData.length > 0 &&
            (!isChartLoading ? (
              <HistoricalChart
                data={chartData}
                width={document.body.offsetWidth - 24}
                height={207}
              />
            ) : (
              <SkeletonTheme baseColor={baseColorSkeleton} highlightColor={highlightColorSkeleton}>
                <div>
                  <Skeleton width={document.body.offsetWidth - 24} height={207} />
                </div>
              </SkeletonTheme>
            ))}
          {selectedChartSection?.description && (
            <div className={style.description}>
              <div> {t('Summary')} </div>
              <p> {selectedChartSection?.description} </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileDetailsPage;
