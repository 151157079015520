import { useEconCalendar } from '../hooks/useEconCalendar';
import EconCalendarTableRows from './EconCalendarTableRows';
import { convertDateToString } from '../utils/util';
import { useEconCalendarParams } from '../hooks/use-econ-calendar-params';
import style from '../styles/EconCalendarTableData.module.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import useScreenDimensions from '../hooks/useScreenDimension';
import EarningsMobileHeadings from './EarningsMobileHeadings';
import { useSessionData } from '../hooks/use-session-data';
import { useTranslation } from 'react-i18next';

export default function EconCalendarTableData() {
  const {
    data,
    dates,
    isDataLoading,
    isTabChanged,
    activeTab,
    showDetail,
    errorMessage,
    showBanner,
  } = useEconCalendar();

  const { t } = useTranslation();

  const { locale, baseColorSkeleton, highlightColorSkeleton } = useEconCalendarParams();
  const { timezoneOffset } = useSessionData();
  const isMobile = useScreenDimensions();
  const uniqueDates =
    dates &&
    dates?.reduce((accumulator, currentValue) => {
      const date = currentValue.substring(0, 10);
      if (data && Object.values(data).length > 0 && data[currentValue]?.length > 0) {
        if (!accumulator[date]) {
          accumulator[date] = [currentValue];
        } else {
          accumulator[date].push(currentValue);
        }
      }
      return accumulator;
    }, {});

  return (
    <div
      className={`${isMobile ? style.mainSectionMobile : style.mainSectionHeight} ${
        style.mainSection
      }`}
      data-testid="data-section"
    >
      {dates &&
      dates.length > 0 &&
      !isDataLoading &&
      !isTabChanged &&
      uniqueDates &&
      Object.keys(uniqueDates) &&
      Object.keys(uniqueDates).length > 0 ? (
        Object.keys(uniqueDates)
          .sort()
          .map((date, _) => {
            return (
              data &&
              Object.entries(data).length > 0 && (
                <div
                  className={isMobile ? style.dateDataWrapperMobile : style.dateDataWrapper}
                  key={date}
                >
                  <div className={isMobile ? style.dateMobileWrapper : style.dateDesktopWrapper}>
                    <div className={`${isMobile ? style.dateMobile : style.date}`}>
                      {convertDateToString(
                        date,
                        timezoneOffset,
                        locale != 'en' ? locale : undefined,
                        t
                      )}
                    </div>
                  </div>
                  {!isMobile && <div className={style.gapFill}></div>}
                  {!showDetail && activeTab === 'earnings-reports' && isMobile && (
                    <EarningsMobileHeadings />
                  )}
                  <div className={isMobile ? style.tableRowsWrapperMobile : style.tableRowsWrapper}>
                    <EconCalendarTableRows data={data} date={date} />
                  </div>
                </div>
              )
            );
          })
      ) : isDataLoading ? (
        <SkeletonTheme baseColor={baseColorSkeleton} highlightColor={highlightColorSkeleton}>
          <div>
            <Skeleton width={1040} height={230} />
            <Skeleton width={1040} height={230} />
            <Skeleton width={1040} height={230} />
          </div>
        </SkeletonTheme>
      ) : errorMessage !== null ? (
        <p className={style.errorMessage}> {errorMessage} </p>
      ) : (
        <p className={style.noDataFound}>
          {t('No events to display. Change the date range or broaden your filters.')}
        </p>
      )}
    </div>
  );
}
