// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aCirLeCqiSjVTyMCphSR {
  /* height: 21px; */
  width: 68px;
  border-radius: 4px;
  padding: 3px 6px;
  text-transform: capitalize;
}

.ZGou67QtHYvcBuVgkSSY {
  height: 18px;
  padding: 2px 0 0 0;
  text-transform: capitalize;
}

.GgDnRnnNWhqqLoVTGxr4 {
  /* height: 13px; */
  font-family: 'MatterIGTF-Medium';
  font-size: 13px;
  line-height: 13px;
  text-align: center;
}

.n8BCEFOdc8sgdLBjrjAX {
  height: 16px;
  width: 20px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
}

.JLo1SS7WKvnbirtWqYUx {
  background-color: var(--high-importance-bg-color);
  color: var(--high-importance-text-color);
}

.p5CRUME5yMtmbxwSfbPn {
  background-color: var(--medium-importance-bg-color);
  color: var(--medium-importance-text-color);
}

.MnoiIXOUP4CSIDugLOlh {
  background-color: var(--low-importance-bg-color);
  color: var(--low-importance-text-color);
}
`, "",{"version":3,"sources":["webpack://./src/styles/Badge.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;EAClB,gCAAgC;EAChC,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,iDAAiD;EACjD,wCAAwC;AAC1C;;AAEA;EACE,mDAAmD;EACnD,0CAA0C;AAC5C;;AAEA;EACE,gDAAgD;EAChD,uCAAuC;AACzC","sourcesContent":[".badgeWrapper {\n  /* height: 21px; */\n  width: 68px;\n  border-radius: 4px;\n  padding: 3px 6px;\n  text-transform: capitalize;\n}\n\n.badgeWrapperMobile {\n  height: 18px;\n  padding: 2px 0 0 0;\n  text-transform: capitalize;\n}\n\n.font {\n  /* height: 13px; */\n  font-family: 'MatterIGTF-Medium';\n  font-size: 13px;\n  line-height: 13px;\n  text-align: center;\n}\n\n.mobileFont {\n  height: 16px;\n  width: 20px;\n  font-size: 12px;\n  line-height: 16px;\n  text-align: center;\n  font-weight: 500;\n  text-transform: uppercase;\n}\n\n.highImportance {\n  background-color: var(--high-importance-bg-color);\n  color: var(--high-importance-text-color);\n}\n\n.mediumImportance {\n  background-color: var(--medium-importance-bg-color);\n  color: var(--medium-importance-text-color);\n}\n\n.lowImportance {\n  background-color: var(--low-importance-bg-color);\n  color: var(--low-importance-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeWrapper": `aCirLeCqiSjVTyMCphSR`,
	"badgeWrapperMobile": `ZGou67QtHYvcBuVgkSSY`,
	"font": `GgDnRnnNWhqqLoVTGxr4`,
	"mobileFont": `n8BCEFOdc8sgdLBjrjAX`,
	"highImportance": `JLo1SS7WKvnbirtWqYUx`,
	"mediumImportance": `p5CRUME5yMtmbxwSfbPn`,
	"lowImportance": `MnoiIXOUP4CSIDugLOlh`
};
export default ___CSS_LOADER_EXPORT___;
