import './index.scss';
import { useEffect, useState } from 'react';
import initialiseI18n from '../i18n';
import { SessionDataProvider } from './hooks/use-session-data';
import { THEME_CLASSES, EconCalendarParamsProvider } from './hooks/use-econ-calendar-params';
import { getEnvURLs } from './utils/env-utils';
import EconCalendar from './components/EconCalendar';
import { EconCalendarProvider } from './hooks/useEconCalendar';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-popper-tooltip/dist/styles.css';
import { supportedLocaleList } from './utils/supportedLocaleList';
import { isDST } from './utils/util';

export default function Root(props) {
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  const igCompanySiteId =
    props?.sessionData?.igCompany || props.econPreferences?.preferences?.igCompany;

  const propsLocale =
    props?.sessionData?.clientLocale?.split('-')[0] ||
    props.econPreferences.preferences.currentLocale;

  const locale =
    propsLocale && supportedLocaleList.includes(propsLocale)
      ? propsLocale
      : props.econPreferences?.preferences?.currentLocale;

  useEffect(() => {
    initialiseI18n(locale).then(() => {
      setTranslationsLoaded(true);
    });
  }, [locale]);

  if (!translationsLoaded) {
    return null;
  }

  const currentAccountId = !props.sessionData ? null : props.sessionData.currentAccountId;
  const themeProp = props.theme?.toUpperCase() || props.econPreferences?.preferences?.currentTheme;
  const theme = !THEME_CLASSES[themeProp] ? THEME_CLASSES.DEFAULT : THEME_CLASSES[themeProp];
  const envURLs = getEnvURLs(props.env || props.econPreferences?.preferences?.env);
  const handleFilter = props.onSelectFilter || function () {};
  const appliedFilters = props.appliedFilters;
  const selectedTabs = props.selectedTabs;
  const isAddToWorkspaceClicked = props.isAddToWorkspaceClicked;
  const authString = btoa(`${envURLs.tastyUserName}:${envURLs.tastyUserPassword}`);
  const timezoneOffsetInit =
    props?.sessionData?.timezoneOffset !== null
      ? Math.abs(props?.sessionData?.timezoneOffset) > 15
        ? props?.sessionData?.timezoneOffset / 60
        : props?.sessionData?.timezoneOffset
      : null;

  const timezoneOffset = isDST(timezoneOffsetInit) ? timezoneOffsetInit + 1 : timezoneOffsetInit;

  return (
    <SessionDataProvider
      currentAccountId={currentAccountId}
      apiHost={envURLs.apiHost}
      xst={props.xst}
      cst={props.cst}
      tastyApiUrl={envURLs.tastyApiUrl}
      timezoneOffset={timezoneOffset}
      igCompanySiteId={igCompanySiteId}
    >
      <EconCalendarParamsProvider theme={theme} locale={locale}>
        <EconCalendarProvider
          handleFilter={handleFilter}
          appliedFilters={appliedFilters}
          selectedTabs={selectedTabs}
          isAddToWorkspaceClicked={isAddToWorkspaceClicked}
          authString={authString}
        >
          <EconCalendar />
        </EconCalendarProvider>
      </EconCalendarParamsProvider>
    </SessionDataProvider>
  );
}
