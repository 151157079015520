import { countryCodeToNameMap } from './countryCodeNameMapping';

export const isDST = (timeZoneOffset) => {
  const now = new Date();
  const january = new Date(now.getFullYear(), 0, 1);

  // Helper function to get the offset in hours
  function getOffset(date) {
    return -date.getTimezoneOffset() / 60;
  }

  // Get the current offset and January offset
  const currentOffset = getOffset(now);
  const januaryOffset = getOffset(january);

  // DST is in effect if the current offset is different from the January offset
  return timeZoneOffset !== januaryOffset;
};

export const convertDateToString = (
  dateString: string,
  offset: number,
  customLocale: string = 'en',
  t
) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  // get the event date relative to the timezone offset
  const finalFormattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
  // getting account's time
  const accountTime = getAccountTime(getLondonTime(), offset);

  const [day, month, yearTime] = accountTime.split('/');
  const [year, time] = yearTime.split(', ');
  const date2 = new Date(`${month}/${day}/${year}`);

  // Calculate the difference in milliseconds
  const diffInMs = new Date(finalFormattedDate).getTime() - date2.getTime();

  // Convert milliseconds to days
  const msInDay = 24 * 60 * 60 * 1000;
  const dayDifference = Math.round(diffInMs / msInDay);

  return dayDifference === -1
    ? `${finalFormattedDate} (${t('yesterday')})`
    : dayDifference === 0
    ? `${finalFormattedDate} (${t('today')})`
    : dayDifference === 1
    ? `${finalFormattedDate} (${t('tomorrow')})`
    : finalFormattedDate;
};

export const formatToTwoDecimalPlaces = (value, decimalPlaces = 2) => {
  try {
    // Extract the preceding text, numeric part (including sign), and the suffix using a regular expression
    const match = value.toString().match(/^([^0-9\-]*-?\d+(\.\d+)?)(.*)/);

    if (!match) {
      return value;
    }

    let numberPart = match[1];
    const suffix = match[3];

    // Find the decimal point in the number part
    const decimalIndex = numberPart.indexOf('.');

    if (decimalIndex !== -1) {
      // If there is a decimal point, truncate to two decimal places without rounding
      numberPart = numberPart.substring(0, decimalIndex + decimalPlaces + 1);
    }
    // Return the truncated number with the suffix
    return `${numberPart}${suffix}`;
  } catch (e) {
    return value;
  }
};

export const formatPaymentDate = (dateString: string, customLocale: string = 'en-US') => {
  if (dateString) {
    // Create a Date object from the string
    const date = new Date(dateString);

    // Get the day of the week (full name)
    const day = new Intl.DateTimeFormat(customLocale, { weekday: 'long' }).format(date);

    // Get the date components (day, month, year)
    const dayOfMonth = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-indexed (January is 0)
    const year = date.getFullYear();

    // Format the date in the desired format
    return `${day}, ${dayOfMonth}/${month.toString().padStart(2, '0')}/${year}`;
  }
};

export const formatPaymentDateDividendsMobile = (dateString: string) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0'); // Add leading zero if necessary
  const month = date.toLocaleString('en-US', { month: 'short' }); // Get short month name
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

export const getFormattedTime = (time, offset) => {
  if (offset !== null) {
    // Given UTC date and time
    const utcDate = new Date(time);

    // Convert UTC date to local date with the specified offset
    const localDate = new Date(utcDate.getTime() + offset * 60 * 60 * 1000);

    // Format the local date to the desired time string
    const hours = String(localDate.getUTCHours()).padStart(2, '0');
    const minutes = String(localDate.getUTCMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  } else {
    time = new Date(time);
    return new Intl.DateTimeFormat('en-GB', {
      timeStyle: 'short',
    }).format(time);
  }
};

export const importanceMapping = {
  0: 'low',
  1: 'low',
  2: 'medium',
  3: 'high',
};

export const getCountryName = (data, key) => {
  return data[key] === 'European Union' ? 'Euro Area' : data[key];
};

// returns the country code of the country with this name
export const searchCountryCode = (name: string): string => {
  // @ts-ignore
  return Object.keys(countryCodeToNameMap).find((key) => countryCodeToNameMap[key] === name);
};

let today = new Date();
let timeOffset = today.getTimezoneOffset() * 60;

export const convertUTCDateToNumber = (dateString: string) => {
  return new Date(dateString).valueOf() / 1000 - timeOffset;
};

export const getLondonTime = () => {
  const now = new Date();
  const options = {
    timeZone: 'Europe/London',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };

  const filteredOptions = Object.fromEntries(
    Object.entries(options).filter(([_, v]) => v !== undefined)
  );
  const formatter = new Intl.DateTimeFormat('en-GB', filteredOptions);

  return formatter.format(now);
};

export const getAccountTime = (londonTimeString: string, offset: number) => {
  // Parse the London time string back into a Date object
  const [datePart, timePart] = londonTimeString.split(', ');
  const [day, month, year] = datePart.split('/');
  const [hour, minute, second] = timePart.split(':');
  const londonDate = new Date(
    Date.UTC(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day),
      parseInt(hour),
      parseInt(minute),
      parseInt(second)
    )
  );

  // Calculate the new time with the given timezone offset
  const utcTime = londonDate.getTime();
  const newTime = new Date(utcTime + offset * 3600000);

  // Format the new time
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'UTC', // Format as UTC since we already adjusted the offset
  };
  const filteredOptions = Object.fromEntries(
    Object.entries(options).filter(([_, v]) => v !== undefined)
  );
  const formatter = new Intl.DateTimeFormat('en-GB', filteredOptions);
  const formattedTime = formatter.format(newTime);
  return formattedTime;
};

const adjustDate = (utcDateString, daysOffset, timezoneOffset, endOfDay = false) => {
  // Parse the given date string (in UTC format)
  const [datePart, timePart] = utcDateString.split(', ');
  const [day, month, year] = datePart.split('/');
  const [hour, minute, second] = timePart.split(':');

  // Create a Date object representing the given date and time in UTC
  const date = new Date(Date.UTC(year, month - 1, day, hour, minute, second));
  // Adjust the date by the given number of days
  date.setUTCDate(date.getUTCDate() + daysOffset);

  if (endOfDay) {
    // Set time to 11:59:59 PM in the adjusted timezone
    date.setUTCHours(23, 59, 59, 999);
  } else {
    // Set time to 12:00 AM in the adjusted timezone
    date.setUTCHours(0, 0, 0, 0);
  }
  // Return the adjusted date in ISO format
  return date.toISOString();
};

export const getCalendarEventRelativeDate = (dateString, timezoneOffset) => {
  // Parse the given date string in YYYY-MM-DD format
  const dateValue = new Date(dateString);

  // Extract the year, month, and day
  const year = dateValue.getFullYear();
  const month = dateValue.getMonth() + 1; // Months are zero-indexed
  const day = dateValue.getDate();
  // Create a Date object representing the given date at 00:00:00 UTC
  const date = new Date(Date.UTC(year, month - 1, day, 0, 0, 0));

  // Extract the integer and fractional parts of the timezoneOffset
  const offsetHours = Math.trunc(timezoneOffset);
  const offsetMinutes = (timezoneOffset - offsetHours) * 60;

  // Calculate the start date adjusted for the timezone offset
  const startDate = new Date(date);
  startDate.setUTCHours(startDate.getUTCHours() - offsetHours);
  startDate.setUTCMinutes(startDate.getUTCMinutes() - offsetMinutes);

  // Calculate the end date adjusted for the timezone offset
  const endDate = new Date(date);
  endDate.setUTCHours(23 - offsetHours);
  endDate.setUTCMinutes(59 - offsetMinutes);
  endDate.setUTCSeconds(59);
  endDate.setUTCMilliseconds(999);
  // Return the adjusted dates in ISO format
  return [startDate.toISOString(), endDate.toISOString()];
};

export const getDateRangeLocal = (activeTab, dateSelected = '') => {
  const fromDate = new Date();
  let toDate = new Date();

  if (dateSelected == '') {
    fromDate.setDate(fromDate.getDate() - 1);
    toDate.setDate(toDate.getDate() + 13);

    const localStart = new Date(
      fromDate.getFullYear(),
      fromDate.getMonth(),
      fromDate.getDate(),
      0,
      0,
      0
    );
    const localEnd = new Date(
      toDate.getFullYear(),
      toDate.getMonth(),
      toDate.getDate(),
      23,
      59,
      59
    );
    const utcStart = new Date(localStart.toISOString());
    const utcEnd = new Date(localEnd.toISOString());

    // 5. Format the dates (optional)
    const utcStartString = utcStart.toISOString();
    const utcEndString = utcEnd.toISOString();
    return [utcStartString, utcEndString];
  } else {
    if (activeTab === 'events') {
      // 1. Parse the input date string
      const date = new Date(dateSelected);

      // 2. Create local date and time for 00:00:00 (ignoring time zone for UTC)
      const localStart = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate());

      // 3. Create local date and time for 23:59:59
      const localEnd = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate(),
        23,
        59,
        59
      );

      // 4. Convert both local dates to UTC
      const utcStart = new Date(localStart.toISOString());
      const utcEnd = new Date(localEnd.toISOString());

      // 5. Format the dates (optional)
      const utcStartString = utcStart.toISOString();
      const utcEndString = utcEnd.toISOString();
      return [utcStartString, utcEndString];
    } else {
      return [dateSelected, dateSelected];
    }
  }
};

export const getDateRangeAccountTime = (
  activeTab,
  timezoneOffset: number | null = null,
  dateSelected = ''
) => {
  const londonTime = getLondonTime();
  const accountTime = getAccountTime(londonTime, timezoneOffset);
  if (dateSelected === '') {
    const dateMinusOneDay = adjustDate(accountTime, -1, timezoneOffset);
    const datePlusThirteenDays = adjustDate(accountTime, 13, timezoneOffset, true);
    return [dateMinusOneDay, datePlusThirteenDays];
  } else {
    if (activeTab === 'events') {
      return getCalendarEventRelativeDate(dateSelected, timezoneOffset);
    } else {
      const year = new Date(dateSelected).getFullYear();
      const month = String(new Date(dateSelected).getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(new Date(dateSelected).getDate()).padStart(2, '0');
      const updatedDate = `${year}-${month}-${day}`;
      return [updatedDate, updatedDate];
    }
  }
};

export const getCountryCode = (countryName) => {
  const countryCode = Object.entries(countryCodeToNameMap).filter(([code, name]) => {
    if (name === countryName) {
      return code;
    }
  });
  return countryCode;
};

export const continents: Record<string, string[]> = {
  Americas: ['US', 'CA', 'MX', 'BR', 'CO'],
  'Euro Area': ['EU', 'DE', 'FR', 'ES', 'IT', 'GR', 'AT', 'IE', 'FI', 'NL', 'PT', 'BE'],
  'Non-Eurozone Europe': ['CZ', 'DK', 'GB', 'SE', 'CH', 'PL', 'RU', 'NO', 'HU'],
  'Asia and Pacific': ['JP', 'AU', 'NZ', 'CN', 'IN', 'KR', 'TH', 'SG', 'HK', 'TW', 'ID', 'PH'],
  'Middle East and Africa': ['IL', 'ZA'],
};

export const eventNameMappingForMobile = {
  events: 'Macroeconomic',
  'earnings-reports': 'Earnings',
  'dividends-reports': 'Dividends',
  ipos: 'IPOs',
  'stock-splits': 'Stock Splits',
};

export const formatDateForAddToCalendar = (inputDate, timezoneOffset) => {
  // Parse the input date
  const date = new Date(inputDate);

  // Apply the timezone offset
  const offsetDate = new Date(date.getTime() + timezoneOffset * 60 * 60 * 1000);

  // Extract day, month, year, hours, minutes, and seconds
  const day = String(offsetDate.getUTCDate()).padStart(2, '0');
  const month = String(offsetDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = offsetDate.getUTCFullYear();
  const hours = String(offsetDate.getUTCHours()).padStart(2, '0');
  const minutes = String(offsetDate.getUTCMinutes()).padStart(2, '0');
  const seconds = String(offsetDate.getUTCSeconds()).padStart(2, '0');

  // Format the date and time
  const formattedDate = `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
  return formattedDate;
};

export const parseDateString = (dateString) => {
  const [datePart, timePart] = dateString.split(', ');
  const [day, month, year] = datePart.split('/');
  const [hours, minutes, seconds] = timePart.split(':');
  return {
    day: parseInt(day, 10),
    month: parseInt(month, 10),
    year: parseInt(year, 10),
    hours: parseInt(hours, 10),
    minutes: parseInt(minutes, 10),
    seconds: parseInt(seconds, 10),
  };
};

export const addToCalendarButtonCheck = (dateStr1, dateStr2) => {
  const date1 = parseDateString(dateStr1);
  const date2 = parseDateString(dateStr2);

  if (date1.year !== date2.year) {
    return date1.year > date2.year;
  }
  if (date1.month !== date2.month) {
    return date1.month > date2.month;
  }
  if (date1.day !== date2.day) {
    return date1.day > date2.day;
  }
  if (date1.hours !== date2.hours) {
    return date1.hours > date2.hours;
  }
  if (date1.minutes !== date2.minutes) {
    return date1.minutes > date2.minutes;
  }
  return date1.seconds > date2.seconds;
};

export const shouldShowAddToCalendar = (date: string, timezoneOffset: number | null = null) => {
  if (timezoneOffset == null) {
    if (new Date(date) > new Date()) return true;
    else return false;
  } else {
    const londonTime = getLondonTime();
    const accountTime = getAccountTime(londonTime, timezoneOffset);
    const eventTime = formatDateForAddToCalendar(date, timezoneOffset);
    // check the account time and event time
    if (addToCalendarButtonCheck(eventTime, accountTime)) return true;
    else return false;
  }
};
