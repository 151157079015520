import Reports from './Reports';
import EarningsReport from './EarningsReport';

export default function TabRowDataIndividiualRow({
  activeTab,
  tabKey,
  index,
  singleDataEntry,
  marketTrend,
  headingsNameCheck,
  dataAccessProperty,
}) {
  const isWorld = ['events', 'earnings-reports'].includes(activeTab)
    ? singleDataEntry?.country?.toUpperCase() === 'WORLD'
    : '';
  return (
    <div className={`${headingsNameCheck[tabKey] || tabKey} font`} key={index}>
      {activeTab !== 'earnings-reports' ? (
        <Reports
          activeTab={activeTab}
          tabKey={tabKey}
          singleDataEntry={singleDataEntry}
          dataAccessProperty={dataAccessProperty}
          isWorld={isWorld}
        />
      ) : (
        <EarningsReport
          tabKey={tabKey}
          singleDataEntry={singleDataEntry}
          marketTrend={marketTrend}
          isWorld={isWorld}
        />
      )}
    </div>
  );
}
