import { useEffect, useRef, useState } from 'react';
import { useEconCalendar } from '../../hooks/useEconCalendar';
import style from '../../styles/filters/ImportanceFilter.module.css';
import { useTranslation } from 'react-i18next';
import CustomCheckbox from './CustomCheckbox';

const importanceLevels = ['high', 'medium', 'low'];

const ImportanceFilter = () => {
  const {
    selectedImportance,
    handleImportance,
    showFilters,
    extendedImportanceFilter,
    updateExtendedImportanceFilter,
  } = useEconCalendar();

  const sectionRef = useRef(null);
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleKeySelection = (importance) => {
    handleImportance(importance);
    updateExtendedImportanceFilter();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const clickedElement = event.target;
      const importanceFilterElement = clickedElement.closest('.importanceFilter'); // Select closest ancestor with class
      if (
        extendedImportanceFilter &&
        sectionRef.current &&
        !sectionRef.current.contains(event.target) &&
        !importanceFilterElement
      ) {
        updateExtendedImportanceFilter();
      }
    };

    const handleKeyDown = (event) => {
      const { key } = event;
      const optionsLength = importanceLevels.length;

      if (key === 'ArrowDown') {
        setSelectedIndex((prevIndex) => (prevIndex < optionsLength - 1 ? prevIndex + 1 : 0));
        event.preventDefault();
      } else if (key === 'ArrowUp') {
        setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : optionsLength - 1));
        event.preventDefault();
      } else if (key === 'Escape') {
        updateExtendedImportanceFilter();
      } else if (key === ' ' || key === 'Enter') {
        handleKeySelection(importanceLevels[selectedIndex]);
      }
      event.stopPropagation();
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [extendedImportanceFilter, selectedIndex, handleKeySelection, updateExtendedImportanceFilter]);

  const handleSelection = (importance, event) => {
    handleImportance(importance);
    event.stopPropagation();
  };

  return (
    <div
      className={showFilters ? style.importanceFilterMobilePage : style.expandedImportanceSection}
      ref={sectionRef}
    >
      {showFilters && <div className={style.importanceHeading}>{t('Importance')}</div>}
      <div className={showFilters ? style.importanceContainerFilterPage : style.expandedContainer}>
        {importanceLevels.map((importance, index) => {
          const styleWrapper =
            importance === 'high'
              ? showFilters
                ? style.highImportanceWrapperFilterPage
                : style.highImportanceWrapper
              : importance === 'medium'
              ? showFilters
                ? style.mediumImportanceWrapperFilterPage
                : style.mediumImportanceWrapper
              : showFilters
              ? style.lowImportanceWrapperFilterPage
              : style.lowImportanceWrapper;
          const styleDiv =
            importance === 'high'
              ? showFilters
                ? style.highImportanceFilterPage
                : style.highImportance
              : importance === 'medium'
              ? showFilters
                ? style.mediumImportanceFilterPage
                : style.mediumImportance
              : showFilters
              ? style.lowImportanceFilterPage
              : style.lowImportance;

          return (
            <div
              key={importance}
              className={`${selectedIndex === index ? style.applyBorder : ''} ${styleWrapper}`}
              data-testid={`div_${importance}`}
            >
              <div className={`${showFilters ? style.checkBoxMobileFilterPage : style.checkBox}`}>
                <div>
                  <input
                    type="checkbox"
                    id={importance}
                    checked={selectedImportance.includes(importance)}
                    onChange={() => handleImportance(importance)}
                    className={style.checkbox_input}
                  />
                  <CustomCheckbox
                    onClickHandler={handleImportance}
                    value={importance}
                    checked={selectedImportance.includes(importance)}
                  />
                </div>
                <button
                  className={styleDiv}
                  onClick={(e) => {
                    handleSelection(importance, e);
                  }}
                  aria-label={t(importance)}
                  data-testid={importance}
                >
                  <span>{t(importance[0])}</span>
                  <span>{t(importance)}</span>
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ImportanceFilter;
