import { useEconCalendar } from '../../hooks/useEconCalendar';
import style from '../../styles/filters/ZoneCountryImportance.module.css';
import { FlagIcon } from 'react-flag-kit';
import { continents } from '../../utils/util';
import useScreenDimensions from '../../hooks/useScreenDimension';
import { useTranslation } from 'react-i18next';
import ExpandFiltersIcon from '../IconComponents/ExpandFiltersIcon';
import CountryFilter from './CountryFilter';
import ImportanceFilter from './ImportanceFilter';

const ZoneCountryImportance = () => {
  const {
    extendedImportanceFilter,
    updateExtendedImportanceFilter,
    updateExtendedCountryFilter,
    activeTab,
    extendedCountryFilter,
  } = useEconCalendar();
  const toShowImportanceFilterSection = ['events', 'earnings-reports'].includes(activeTab);
  const isMobile = useScreenDimensions();
  const { t } = useTranslation();

  return (
    <div className={style.zoneCountryImportance}>
      {toShowImportanceFilterSection && (
        <div className={style.filtersWrapper}>
          <button
            className={`${style.alignment} importanceFilter`}
            onClick={updateExtendedImportanceFilter}
            data-testid="importance_filter_expand"
          >
            <div className={`${style.importance} ${style.filterHeading}`}>
              <div> {t('Importance')} </div>
              <div className={`${isMobile ? style.mobileFlags : style.importanceLevels}`}>
                <span className={style.highImportance}>
                  <p> {t('H')} </p>
                </span>
                <span className={style.mediumImportance}>
                  <p> {t('M')} </p>
                </span>
                <span className={style.lowImportance}>
                  <p> {t('L')} </p>
                </span>
              </div>
            </div>
            <ExpandFiltersIcon />
          </button>
          {extendedImportanceFilter && !isMobile && <ImportanceFilter />}
        </div>
      )}
      <div className={style.filtersWrapper}>
        <button
          className={`${style.alignment} countryFilter`}
          onClick={updateExtendedCountryFilter}
          aria-label={t('Toggle Country Filter')} // ARIA label for accessibility
          aria-expanded={extendedCountryFilter ? 'true' : 'false'} // Indicates if the filter is expanded or not
          data-testid="country_filter_expand"
        >
          <div className={`${style.country} ${style.filterHeading}`}>
            <div> {t('Country/Region')} </div>
            <div className={`${isMobile ? style.mobileFlags : style.flags}`}>
              <div>
                <FlagIcon code="US" />
              </div>
              <div>
                <FlagIcon code="GB" />
              </div>
              <div>
                <FlagIcon code="EU" />
              </div>
              <div className={style.flagCount}>
                +{Object.values(continents).flatMap((countryList) => countryList).length - 3}
              </div>
            </div>
          </div>
          <ExpandFiltersIcon />
        </button>
        {extendedCountryFilter && !isMobile && <CountryFilter />}
      </div>
    </div>
  );
};

export default ZoneCountryImportance;
