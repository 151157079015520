import style from '../styles/EarningsMobileHeadings.module.css';
import { useTranslation } from 'react-i18next';

const EarningsMobileHeadings = () => {
  const { t } = useTranslation();
  return (
    <div className={style.headingsWrapper}>
      <div className={style.emptyBox}></div>
      <div className={style.headings}>
        <span> {t('Actual')} </span>
        <span> {t('Forecast')} </span>
        <span> {t('Previous')} </span>
      </div>
    </div>
  );
};

export default EarningsMobileHeadings;
