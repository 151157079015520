import { DividendsReport } from '../types/dividendsReport';
import { EarningsReport } from '../types/earningsReport';
import { IPO } from '../types/ipos';
import { MacroEvent } from '../types/macroEvent';
import { StockSplit } from '../types/stock-splits';
import { getLondonTime, getAccountTime } from './util';

/*
  Function name: handleEconProcessing
  Input: api response data
  Description: takes in the data from api and processes it to collect dates and the data
  Returns: returns the dates and the data received within the api response
*/
export function handleEconProcessing(respData) {
  let newEventsGroupedByDate: Record<
    string,
    DividendsReport[] | EarningsReport[] | IPO[] | MacroEvent[] | StockSplit[]
  > = {};
  let newDates: string[] = [];

  respData.forEach((event) => {
    let key = event.date ? 'date' : 'exDate';
    let date = event[key]; // Assumes date format is "YYYY-MM-DD"

    if (!newDates.includes(date)) {
      newDates.push(date);
    }

    if (!(date in newEventsGroupedByDate)) {
      newEventsGroupedByDate[date] = [];
    }
    newEventsGroupedByDate[date].push(event);
  });

  return { datesData: newDates, processedData: newEventsGroupedByDate };
}

/*
  Function name: convertDateToUserTimezone
  Input: event date string and timezone offset value
  Description: takes in the event time and returns the relative time according to 
               the timezone offset in 2 formats, one for comparison and other for
               displaying the event time
  Returns: returns relative time in 2 formats, one for comparison and other for 
           displaying event time
*/
function convertDateToUserTimezone(dateString: string, offsetHours: number) {
  // Parse the UTC date string
  const utcDate = new Date(dateString);
  let formattedDate, formattedDateNormal;
  if (offsetHours !== null) {
    // Calculate the target timezone offset in milliseconds
    const targetTimezoneOffset = offsetHours * 60 * 60 * 1000;

    // Adjust the date to the target timezone
    const targetDate = new Date(utcDate.getTime() + targetTimezoneOffset);

    // Format the date to ISO 8601 format
    formattedDate = targetDate.toISOString().slice(0, 16) + 'Z';

    // Format the adjusted date to the desired string format
    const options = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      timeZone: 'UTC',
    };
    const filteredOptions = Object.fromEntries(
      Object.entries(options).filter(([_, v]) => v !== undefined)
    );

    const formatter = new Intl.DateTimeFormat('en-US', filteredOptions);
    formattedDateNormal = new Date(formatter.format(targetDate));
  } else {
    // Get the user's local time zone offset in minutes
    const userTimezoneOffset = utcDate.getTimezoneOffset();

    // Adjust the date for the user's time zone
    const userTimezoneDate = new Date(utcDate.getTime() - userTimezoneOffset * 60000);

    // Format the date in the desired format (YYYY-MM-DDTHH:mmZ)
    formattedDate = userTimezoneDate.toISOString().slice(0, 16) + 'Z';
    formattedDateNormal = new Date(dateString);
  }

  return [formattedDateNormal, formattedDate];
}

const getLocalTime = (date = new Date()) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Construct the formatted date string
  const formattedDate = `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;

  return formattedDate;
};

function parseDateString(dateString) {
  // Split the date string into its components
  const [datePart, timePart] = dateString.split(', ');
  const [day, month, year] = datePart.split('/');
  const [hours, minutes, seconds] = timePart.split(':');

  // Create a Date object from the parsed components
  const date = new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);

  return date;
}

/*
  Function name: getDateFilterRange
  Input: NA
  Description: returns the dates corresponding to the required filters
  Returns: an object with the required date filters
*/
const getDateFilterRange = (timezoneOffset: number) => {
  const date =
    timezoneOffset !== null ? getAccountTime(getLondonTime(), timezoneOffset) : getLocalTime();
  const todayDate = parseDateString(date);

  const yesterdayDate = new Date(todayDate);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);
  const tomorrowDate = new Date(todayDate);
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);

  const next7daysDate = new Date(todayDate);
  next7daysDate.setDate(next7daysDate.getDate() + 7);
  const next14daysDate = new Date(todayDate);
  next14daysDate.setDate(next14daysDate.getDate() + 14);

  return { todayDate, yesterdayDate, tomorrowDate, next7daysDate, next14daysDate };
};

/*
  Function name: isSameDay
  Input: 2 date objects 
  Description: Takes in 2 date objects and checks if the dates are same or not
  Returns: returns true if dates are same else false
*/
const isSameDay = (date1, date2, timezoneOffset) => {
  return (
    new Date(date1).getFullYear() === date2.getFullYear() &&
    new Date(date1).getMonth() === date2.getMonth() &&
    new Date(date1).getDate() === date2.getDate()
  );
};

/*
  Function name: filterOutHolidays
  Input: object with data filtered on date & time
  Description: Takes in the filtered data as object and finds out the holidays within it
  Returns: Segregate the holidays and returns the data
*/
const filterOutHolidays = (filteredData: {}) => {
  const holidaySortedData = {};

  filteredData &&
    Object.keys(filteredData).forEach((key) => {
      const data = filteredData[key];
      data.forEach((value: {}) => {
        if (value['category'] === 'Holidays') {
          const dateTime = new Date(key.split('T')[0] + 'T00:00Z').toISOString().slice(0, 16) + 'Z';
          if (dateTime in holidaySortedData) {
            value['date'] = new Date(key.split('T')[0] + 'T00:00').toISOString().slice(0, 19) + 'Z';
            holidaySortedData[dateTime].unshift(value);
          } else {
            value['date'] = new Date(key.split('T')[0] + 'T00:00').toISOString().slice(0, 19) + 'Z';
            holidaySortedData[dateTime] = [value];
          }
        } else {
          if (key in holidaySortedData) {
            holidaySortedData[key].push(value);
          } else {
            holidaySortedData[key] = [value];
          }
        }
      });
    });
  return holidaySortedData;
};

/*
  Function name: sortData
  Input: object with data filtered on date & time with holidays separated
  Description: Takes in the data as object and sort it based on the keys (timestamp) to display the data in order
  Returns: Sorted data on the basis of timestamp
*/
const sortData = (data: {}) => {
  let finalData = {};
  let sortedKeys = data && Object.keys(data).sort();
  sortedKeys.forEach((key) => {
    finalData[key] = data[key];
  });

  return finalData;
};

/*
  Function name: sortHolidaysAtTop
  Input: object with data filtered on the basis of dates and time
  Description: Takes in the filtered data based on date and time and find the holidays based on the category flag in the data.
  Returns: Filtered data based on date and time with holidays on the top for every date
*/
const sortHolidaysAtTop = (filteredData: {}) => {
  const holidaySortedData = {
    yesterday: {},
    today: {},
    tomorrow: {},
    'next 7 days': {},
    'next 14 days': {},
    'custom date': {},
  };

  Object.keys(holidaySortedData).forEach(
    (value) => (holidaySortedData[value] = filterOutHolidays(filteredData[value]))
  );

  let finalData = {
    yesterday: {},
    today: {},
    tomorrow: {},
    'next 7 days': {},
    'next 14 days': {},
    'custom date': {},
  };

  finalData &&
    Object.keys(finalData).forEach(
      (value) => (finalData[value] = sortData(holidaySortedData[value]))
    );
  return finalData;
};

/*
  Function name: filterDataOnDateFilters
  Input: data received from api & date flag (when a custom date is selected from calendar)
  Description: Takes in the processed data received from the api & returns filtered data with holidays based on date and event timestamp
  Returns: sorted data ready to be displayed across yesterday, today, tomorrow, next 7 days and next 14 days
*/
export const filterDataOnDateFilters = (
  processedData,
  dateCheck: boolean,
  timezoneOffset: number
) => {
  const filteredData = {
    yesterday: {},
    today: {},
    tomorrow: {},
    'next 7 days': {},
    'next 14 days': {},
    'custom date': {},
  };

  const { todayDate, yesterdayDate, tomorrowDate, next7daysDate, next14daysDate } =
    getDateFilterRange(timezoneOffset);
  Object.keys(processedData).forEach((date) => {
    const [dateRelative, relativeDate] = convertDateToUserTimezone(date, timezoneOffset);
    if (!dateCheck) {
      filteredData['custom date'][relativeDate] = processedData[date];
    } else {
      if (isSameDay(dateRelative, yesterdayDate, timezoneOffset)) {
        filteredData['yesterday'][relativeDate] = processedData[date];
      } else if (isSameDay(dateRelative, todayDate, timezoneOffset)) {
        filteredData['today'][relativeDate] = processedData[date];
      } else if (isSameDay(dateRelative, tomorrowDate, timezoneOffset)) {
        filteredData['tomorrow'][relativeDate] = processedData[date];
      }
      if (dateRelative > todayDate && dateRelative <= next7daysDate) {
        filteredData['next 7 days'][relativeDate] = processedData[date];
      }
      if (dateRelative > todayDate && dateRelative <= next14daysDate) {
        filteredData['next 14 days'][relativeDate] = processedData[date];
      }
    }
  });

  return sortHolidaysAtTop(filteredData);
};

/*
  Function name: filterDatesOnDateFilters
  Input: dates received from api & date flag (when a custom date is selected from calendar)
  Description: Takes in the dates received from the api and returns the dates filtered across the required filters
  Returns: filtered dates across yesterday, today, tomorrow, next 7 days and next 14 days
*/
export const filterDatesOnDateFilters = (
  datesData: string[],
  dateCheck: boolean,
  timezoneOffset: number
) => {
  const filteredDatesData = {
    today: [],
    yesterday: [],
    tomorrow: [],
    'next 7 days': [],
    'next 14 days': [],
    'custom date': [],
  };

  const { todayDate, yesterdayDate, tomorrowDate, next7daysDate, next14daysDate } =
    getDateFilterRange(timezoneOffset);
  datesData.forEach((date) => {
    const [dateValue, relativeDate] = convertDateToUserTimezone(date, timezoneOffset);
    const dateObject = new Date(dateValue);

    if (!dateCheck) {
      filteredDatesData['custom date'].push(relativeDate);
    } else {
      if (isSameDay(dateObject, yesterdayDate, timezoneOffset)) {
        filteredDatesData['yesterday'].push(relativeDate);
      } else if (isSameDay(dateObject, todayDate, timezoneOffset)) {
        filteredDatesData['today'].push(relativeDate);
      } else if (isSameDay(dateObject, tomorrowDate, timezoneOffset)) {
        filteredDatesData['tomorrow'].push(relativeDate);
      }

      if (dateObject > todayDate && dateObject <= next7daysDate) {
        filteredDatesData['next 7 days'].push(relativeDate);
      }

      if (dateObject > todayDate && dateObject <= next14daysDate) {
        filteredDatesData['next 14 days'].push(relativeDate);
      }
    }
  });

  return filteredDatesData;
};
