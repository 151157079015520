import style from '../styles/EconCalendarFilters.module.css';
import ZoneCountryImportance from './Filters/ZoneCountryImportance';
import SearchBox from './Filters/SearchBox';
import { useEconCalendar } from '../hooks/useEconCalendar';
import filterExpandIcon from '../assets/svgs/mobileFilterExpandIcon.svg';
import useScreenDimensions from '../hooks/useScreenDimension';
import searchIcon from '../assets/svgs/SearchExpandIcon.svg';
import { useTranslation } from 'react-i18next';

export default function EconCalendarFilters() {
  const { updateShowSearch, updateShowFilters, showFilters, showSearch } = useEconCalendar();
  const { t } = useTranslation();
  const isMobile = useScreenDimensions();
  const showSearchClass = showSearch ? style.filterWrapperMobileSearch : style.filterWrapperMobile;

  return (
    <div className={`${isMobile ? showSearchClass : style.filterWrapper}`}>
      {isMobile ? (
        <div className={style.mobileFilterView}>
          {!showFilters && !showSearch && (
            <button onClick={updateShowFilters} data-testid="mobile-expand-filters">
              <img src={filterExpandIcon} alt={t('expand filters')} loading="lazy" />
            </button>
          )}
          {!showFilters && !showSearch && (
            <button onClick={updateShowSearch} data-testid="mobile-expand-search">
              <img src={searchIcon} alt={t('search icon')} loading="lazy" />
            </button>
          )}
        </div>
      ) : (
        <div className={style.filter}>
          <ZoneCountryImportance />
          <SearchBox />
        </div>
      )}
    </div>
  );
}
